import ReactGA from 'react-ga4';
import { Flex, Box, Text, chakra, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import AchievementCardModal from './AchievementCardModal';

const AchievementCard = props => {
  const { t } = useTranslation();
  const { time, events } = props;

  const gaButtonHandler = event => {
    ReactGA.event({
      category: 'achievement',
      action: `achievement_${event}_click`,
    });
  };

  return (
    <Flex direction="column" position="relative" p="28px" my="20px">
      {/* 三角形 */}
      <Box
        w="0"
        h="0"
        borderWidth="30px 30px 0 0"
        borderColor="#00FFB0 transparent transparent transparent"
        position="absolute"
        top="0"
        left="0"
      ></Box>
      {/* 第一排 */}
      <Text textTransform="uppercase" fontSize="30px" lineHeight="30px">
        {time}
      </Text>
      {events.map(event => (
        <Flex
          justify="space-between"
          key={event.title}
          position="relative"
          pb="10px"
          role="group"
        >
          <Text
            fontWeight="500"
            color="green.500"
            fontSize="30px"
            pt="24px"
            flex="1"
            pr={{ base: '0', lg: '20px' }}
          >
            <chakra.span
              color="white"
              pr="10px"
              _groupHover={{ color: 'green.500' }}
            >{`{`}</chakra.span>
            <Link href={event.link} isExternal>
              {t(event.title)}
            </Link>
            <chakra.span
              color="white"
              pl="10px"
              _groupHover={{ color: 'green.500' }}
            >{`}`}</chakra.span>
          </Text>
          <Flex position="absolute" right="0" top="-2px">
            {event.image && (
              <AchievementCardModal
                handleClick={() => gaButtonHandler(t(event.title))}
                {...event}
              />
            )}
            {event.link && (
              <Link
                href={event.link}
                isExternal
                onClick={() => gaButtonHandler(t(event.title))}
              >
                <Text
                  _groupHover={{ opacity: '1' }}
                  textAlign="right"
                  // pt="32px"
                  opacity="0.45"
                  transition="all 0.3s ease-in-out"
                  _hover={{ opacity: '1' }}
                >
                  READ MORE →
                </Text>
              </Link>
            )}
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default AchievementCard;
