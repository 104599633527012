import ReactGA from 'react-ga4';

import '@fontsource/ibm-plex-mono/400.css';
import '@fontsource/ibm-plex-mono/500.css';
import '@fontsource/ibm-plex-mono/700.css';

import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ChakraProvider } from '@chakra-ui/react';

import theme from './theme/theme';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import { translationsEn, translationsZh } from './i18n';
import LoadingOverlay from './components/LoadingOverlay';
import Header from './components/sections/Header';
import Footer from './components/sections/Footer';
import JoinUs from './components/sections/JoinUs';
// import Home from './pages/Home';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: { translation: translationsEn },
      zh: { translation: translationsZh },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
  });

//GA setting

ReactGA.initialize('G-S6ZRDB1MCC');
ReactGA.send('https://zinstitute.net/');

const pathname = window.location.pathname.split('/')[1];

const history = window.history;
const localStorageLanguage = localStorage.getItem('language');

if (pathname === 'en' || pathname === 'zh') {
  i18n.changeLanguage(pathname);
} else if (localStorageLanguage) {
  history.replaceState(null, '', `/${localStorageLanguage}`);
  i18n.changeLanguage(localStorageLanguage);
} else {
  history.replaceState(null, '', '/en');
}

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <ColorModeScript />
      <ChakraProvider theme={theme}>
        <LoadingOverlay />
        <Header />
        <JoinUs />
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/:languageId" element={<App />} />
          <Route path="/" element={<Navigate replace to="/en" />} />
          <Route path="*" element={<Navigate replace to="/en" />} />
        </Routes>
        <Footer />
      </ChakraProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
