import { useEffect, useRef, useState } from 'react';
import { animate, motion } from 'framer-motion';
import { Flex, Text } from '@chakra-ui/react';

const MotionText = motion(Text);

const variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'tween',
      duration: 0.5,
    },
  },
  hidden: {
    opacity: 0.2,
  },
};

const Counter = ({ from, to, time, fontSize }) => {
  const nodeRef = useRef(0);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const node = nodeRef.current;
    if (isShow) {
      const controls = animate(from, to, {
        duration: time || 1.5,
        type: 'Tween',
        onUpdate(value) {
          node.textContent = value.toFixed();
        },
      });

      return () => controls.stop();
    }
  }, [from, to, isShow, time]);

  return (
    <Flex minW="170px" justify="flex-end">
      <MotionText
        onViewportEnter={() => {
          setIsShow(true);
        }}
        onViewportLeave={() => {
          setIsShow(false);
        }}
        initial="visible"
        whileInView="visible"
        viewport={{ once: false, amount: 0.5 }}
        variants={variants}
        ref={nodeRef}
        fontSize={fontSize || '70px'}
        fontWeight="700"
      />
    </Flex>
  );
};

export default Counter;
