import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Button,
  Text,
  HStack,
  Image,
  Flex,
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';

import logoZ from '../assets/images/logo/logo_z.svg';
import modalClose from '../assets/images/modal_close.svg';

const AchievementCardModal = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { title, image, handleClick } = props;
  const { t } = useTranslation();

  return (
    <>
      <Flex>
        <Button
          opacity=".45"
          onClick={() => {
            onOpen();
            handleClick();
          }}
          mr="0"
          pr="0"
          py="0"
          h="24px"
          fontWeight="300"
          textAlign="right"
          bg="none"
          _groupHover={{ opacity: '1' }}
          _hover={{
            bg: 'none',
            opacity: '1',
          }}
          _active={{
            border: 'none',
            shadow: 'none',
          }}
          _focus={{
            border: 'none',
            shadow: 'none',
          }}
        >
          READ MORE →
        </Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} w="auto">
        <ModalOverlay />
        <ModalContent
          bg="black"
          border="1px"
          borderColor="green.500"
          h="600px"
          w="1000px"
          maxW="unset"
        >
          {/* <ModalHeader borderBottom="1px solid red" h="36px"> */}
          <Flex
            w="100%"
            h="36px"
            align="center"
            borderBottom="1px"
            borderColor="green.500"
            justify="space-between"
            px="20px"
          >
            <HStack flex="1">
              <Image src={logoZ} h="12px" />
              <Text
                fontSize="15px"
                color="green.500"
                whiteSpace="noWrap"
                w={{ base: '200px', lg: 'unset' }}
                display={{ base: 'none', lg: 'block' }}
                overflow="hidden"
              >
                {t(title)}
              </Text>
            </HStack>
            <Image src={modalClose} onClick={onClose} cursor="pointer" />
            <HStack></HStack>
          </Flex>
          {/* </ModalHeader> */}
          <ModalBody display="flex" alignItems="center" justifyContent="center">
            {/* <Text textTransform="uppercase" fontSize="30px" color="green.500">
              {title}
            </Text> */}
            <Image
              src={image}
              w="100%"
              maxW="800px"
              maxH="300px"
              objectFit="contain"
              rounded="xl"
            />
          </ModalBody>
          {/* <ModalFooter>
            <Button bg="sun" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default AchievementCardModal;
