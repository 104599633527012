const colors = {
  grey: {
    900: '#1F1F1F',
    800: '#333',
    700: '#404040',
    600: '#484848',
  },
  whiteO: {
    700: 'rgba(255,255,255,.7)',
    400: 'rgba(255,255,255,.4)',
  },
  blackO: {
    900: 'rgba(0,0,0,.9)',
    700: 'rgba(0,0,0,.7)',
    100: 'rgba(0,0,0,.0)',
  },
  green: {
    500: '#00FFB0',
    400: '#00FFB0',
  },
};

export default colors;
