import { useState, useEffect } from 'react';
import Papa from 'papaparse';

const useCSV = file => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetch(file);
      const reader = response.body.getReader();
      const result = await reader.read(); // raw array
      const decoder = new TextDecoder('utf-8');
      const csv = decoder.decode(result.value); // the csv text
      const results = Papa.parse(csv, { header: true }); // object with { data, errors, meta }
      const rows = results.data; // array of objects
      setData(rows);
    }
    getData();
  }, [file]);

  return {
    data,
  };
};

export default useCSV;
