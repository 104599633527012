import ReactGA from 'react-ga4';
import { Flex, Text, Image, Link } from '@chakra-ui/react';
import Slick from './SlickCenterMode';

import work01 from '../assets/images/work01.png';
import work02 from '../assets/images/work/work_nft_01.jpg';
import work03 from '../assets/images/work/work_nft_02.jpg';

const works = [
  {
    id: 'work01',
    image: work01,
    content: 'Smart Contract Developer Bootcamp : Daily Homework',
    link: 'https://github.com/z-institute/Solidity-EVM-Dev-Batch-1-HW',
  },
  {
    id: 'work02',
    image: work02,
    content: `NFT & GameFi Talents Incubator graduation results : they’re watching`,
    link: 'https://www.fxhash.xyz/generative/10539',
  },
  {
    id: 'work03',
    image: work03,
    content:
      'NFT & GameFi Talents Incubator graduation results : Light of consensus',
    link: 'https://www.fxhash.xyz/generative/slug/light-of-consensus',
  },
];

const slickSettings = {
  dots: true,
  arrows: false,
  autoplay: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: '20%',
  className: 'center',
  centerMode: true,
  onSwipe: () => {
    ReactGA.event({
      category: 'EXCELLENT WORK',
      action: 'workSwipe',
    });
  },
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '4%',
        arrows: false,
      },
    },
  ],
};

const WorksSlick = () => {
  return (
    <Flex>
      <Slick
        slickSetting={slickSettings}
        style={{
          display: 'flex',
          justify: 'center',
          alignItems: 'stretch',
          position: 'relative',
          width: '100%',
          borderRadius: '5px',
        }}
      >
        {works.map(work => (
          <Flex key={work.id} h="100%" w="800px" justify="center" px="20px">
            <Flex w="100%" align="center" justify="center" direction="column">
              <Image
                src={work.image}
                alt="work01"
                border="1px"
                borderColor="green.500"
                rounded="xl"
              />
              <Link href={work.link} isExternal>
                <Text
                  textDecoration="underline"
                  fontSize={{ base: '14px', lg: '20px' }}
                  textAlign="center"
                  pt="20px"
                  pb="5px"
                >
                  {work.content}
                </Text>
              </Link>
            </Flex>
          </Flex>
        ))}
      </Slick>
    </Flex>
  );
};

export default WorksSlick;
