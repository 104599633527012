import { Flex, Text } from '@chakra-ui/react';
import SocialLink from '../SocialLink';

const JoinUs = () => {
  return (
    <Flex
      display={{ base: 'flex', md: 'none' }}
      justify="space-between"
      h="48px"
      bg="rgba(0,0,0,0.8)"
      position="fixed"
      bottom="0"
      w="100%"
      align="center"
      px="20px"
      borderTop="1px"
      borderColor="green.500"
      zIndex="20"
      style={{
        backdropFilter: ' blur(14px)',
        WebkitBackdropFilter: 'blur(14px)',
      }}
    >
      <Text textTransform="uppercase">Join us</Text>
      <SocialLink />
    </Flex>
  );
};

export default JoinUs;
