import ReactGA from 'react-ga4';

import {
  Flex,
  Box,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Image,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import useSpace from '../../hooks/useSpace';
import { useTranslation } from 'react-i18next';
import questionSvg from '../../assets/images/questions.svg';

const MotionImage = motion(Image);
const MotionFlex = motion(Flex);

const questionsList = [
  {
    question: 'q1',
    answer: 'a1',
  },
  {
    question: 'q2',
    answer: 'a2',
  },
  {
    question: 'q3',
    answer: 'a3',
  },
  {
    question: 'q4',
    answer: 'a4',
  },
];

const Questions = () => {
  const { t } = useTranslation();
  const { space } = useSpace();

  const gaButtonHandler = question => {
    ReactGA.event({
      category: 'qa_',
      action: `qa_${question}`,
    });
  };

  const variants = {
    visible: {
      opacity: 0.5,
      scale: 1,
      transition: {
        duration: 2,
        ease: 'easeInOut',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    hidden: {
      opacity: 0.4,
      pathLength: 0,
    },
  };

  return (
    <MotionFlex
      w="100%"
      justify="center"
      my={{ base: '40px', lg: '80px' }}
      px="18px"
      position="relative"
      overflow="hidden"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: false, amount: 0.5 }}
    >
      <MotionImage
        variants={variants}
        src={questionSvg}
        position="absolute"
        right="-100px"
        bottom="20px"
        zIndex="-1"
      />
      <Flex w={space} direction="column">
        <Heading
          pb={{ base: '12px', lg: '80px' }}
          fontSize={{ base: '45px', lg: '90px' }}
        >
          Commons
          <br />
          Questions
        </Heading>
        <Accordion allowToggle w={{ base: '92%', lg: '70%' }}>
          {questionsList.map(faq => (
            <AccordionItem
              my={{ base: '20px', lg: '60px' }}
              border="0"
              key={faq.question}
              onClick={() => gaButtonHandler(t(faq.question))}
            >
              {({ isExpanded }) => (
                <>
                  <Flex
                    zIndex="1"
                    border="0"
                    borderLeft="1px"
                    direction="column"
                    pl="20px"
                    borderColor={isExpanded ? 'green.500' : 'gray.500'}
                    _hover={{ borderColor: 'green.500' }}
                  >
                    <AccordionButton _focus={{ outline: 'none' }}>
                      <Box
                        flex="1"
                        textAlign="left"
                        fontSize={{ base: '15px', lg: '30px' }}
                      >
                        {t(faq.question)}
                      </Box>
                      {/* <AccordionIcon /> */}
                    </AccordionButton>

                    <AccordionPanel
                      pt="22px"
                      pb={4}
                      color="green.500"
                      fontSize={{ base: '15px', lg: '30px' }}
                    >
                      {t(faq.answer)}
                    </AccordionPanel>
                  </Flex>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>
    </MotionFlex>
  );
};

export default Questions;
