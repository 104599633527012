import {
  Flex,
  Heading,
  Text,
  Grid,
  GridItem,
  Image,
  AspectRatio,
  Link,
  Badge,
} from '@chakra-ui/react';

import useSpace from '../../hooks/useSpace';
import { useTranslation } from 'react-i18next';

import new01 from 'assets/images/news/new_01.jpg';
import new02 from 'assets/images/news/new_02.jpg';
import new03 from 'assets/images/news/new_03.jpg';

const newsList = [
  {
    title: 'news_01_title',
    content: 'news_01_content',
    link: 'https://medium.com/the-z-institute/znft-web-3-%E5%85%83%E5%AE%87%E5%AE%99%E5%A4%A7%E5%AD%B8%E7%9A%84%E9%80%9A%E8%A1%8C%E8%AD%89-8d2e73b2a4cf',
    img: new01,
    type: 'article',
    resource: 'Medium',
  },
  {
    title: 'news_02_title',
    content: 'news_02_content',
    link: 'https://www.abmedia.io/20220307-the-z-institute-speed-dating',
    img: new02,
    type: 'news',
    resource: 'ABMedia',
  },
  {
    title: 'news_03_title',
    content: 'news_03_content',
    link: 'https://www.abmedia.io/20220413-the-difference-between-erc721-erc721a-erc721psi-erc721r',
    img: new03,
    type: 'news',
    resource: 'ABMedia',
  },
];

const NewsItem = props => {
  const { t } = useTranslation();
  const { img, title, content, type, resource, link } = props;
  return (
    <GridItem
      display="flex"
      flexDirection="column"
      border="2px solid #444"
      rounded="lg"
      overflow="hidden"
      bg="#00000060"
      transition="all .3s ease"
      cursor="pointer"
      pb="10px"
      _hover={{ boxShadow: '0 0 15px 0 #00ffb080' }}
    >
      <Link
        href={link}
        isExternal
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flex="1"
      >
        <AspectRatio ratio={450 / 250}>
          <Image src={img} objectFit="cover" />
        </AspectRatio>
        <Flex direction="column" flex="1" justifyContent="flex-start">
          <Flex pt="20px" px="20px" align="center" h="40px">
            <Badge variant="solid" colorScheme="green" color="black">
              {type}
            </Badge>
            <Text ml="20px">{resource}</Text>
          </Flex>
          <Text
            p="10px"
            px="20px"
            fontSize="22px"
            lineHeight="24px"
            fontWeight="500"
            opacity=".9"
            flex="1"
          >
            {t(title)}
          </Text>
          <Flex flex="1" align="flex-end">
            <Text
              px="20px"
              fontSize="14px"
              fontWeight="500"
              opacity=".5"
              noOfLines={2}
            >
              {t(content)}
            </Text>
          </Flex>
        </Flex>
      </Link>
    </GridItem>
  );
};

const News = () => {
  const { space } = useSpace();
  return (
    <Flex
      w="100%"
      py="60px"
      borderTop="1px"
      borderBottom="1px"
      borderColor="green.500"
      justify="center"
      bg="linear-gradient(45deg, #00FFB015 50%, #010101 50%)"
      bgSize="20px 20px"
    >
      <Flex w={space} direction="column" px="15px">
        <Heading align="left" fontSize={{ base: '60px', lg: '72px' }} pb="30px">
          News
        </Heading>
        <Grid
          w="100%"
          templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }}
          gap={5}
        >
          {newsList.map(news => (
            <NewsItem
              key={news.title}
              img={news.img}
              title={news.title}
              content={news.content}
              type={news.type}
              resource={news.resource}
              link={news.link}
            />
          ))}
        </Grid>
      </Flex>
    </Flex>
  );
};

export default News;
