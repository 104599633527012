import ReactGA from 'react-ga4';
import { Flex, Box } from '@chakra-ui/react';
import { Element } from 'react-scroll';
import { motion } from 'framer-motion';

import Hero from '../components/sections/Hero';
import HackTheFuture from '../components/sections/HackTheFuture';
import About from '../components/sections/About';
import Vision from '../components/sections/Vision';
import Service from '../components/sections/Service';
import CourseCalendar from '../components/sections/CourseCalendar';
import Achievement from '../components/sections/Achievement';
import RoadMap from '../components/sections/RoadMap';
import Instructors from '../components/sections/Instructors';
import Works from '../components/sections/Works';
import Partner from '../components/sections/Partner';
import Questions from '../components/sections/Questions';
import Contact from '../components/sections/Contact';
import News from '../components/sections/News';

import CallToActionButton from '../components/CallToActionButton';

const SectionOnViewPortEnter = sectionTitle => {
  ReactGA.event({
    category: 'test',
    action: `${sectionTitle}OnViewPortEnter`,
  });
};

const MotionBox = motion(Box);

const Home = () => {
  const sectionsList = [
    {
      name: 'hero',
      isAnchor: true,
      component: <Hero />,
    },
    {
      name: 'HackTheFuture',
      isAnchor: false,
      component: <HackTheFuture />,
    },
    {
      name: 'about',
      isAnchor: true,
      component: <About />,
    },
    {
      name: 'vision',
      isAnchor: true,
      component: <Vision />,
    },
    {
      name: 'service',
      isAnchor: true,
      component: <Service />,
    },
    {
      name: 'course',
      isAnchor: true,
      component: <CourseCalendar />,
    },
    {
      name: 'Achievement',
      isAnchor: false,
      component: <Achievement />,
    },
    {
      name: 'roadmap',
      isAnchor: true,
      component: <RoadMap />,
    },
    {
      name: 'instructor',
      isAnchor: true,
      component: <Instructors />,
    },
    {
      name: 'works',
      isAnchor: false,
      component: <Works />,
    },
    {
      name: 'partner',
      isAnchor: true,
      component: <Partner />,
    },
    {
      name: 'news',
      isAnchor: true,
      component: <News />,
    },
    {
      name: 'Q&A',
      isAnchor: true,
      component: <Questions />,
    },
    {
      name: 'Contact',
      isAnchor: true,
      component: <Contact />,
    },
  ];

  return (
    <>
      <Flex direction="column">
        {sectionsList.map(section => (
          <MotionBox
            key={section.name}
            onViewportEnter={() => SectionOnViewPortEnter(section.name)}
            w="100%"
            justify="center"
          >
            {section.isAnchor ? (
              <Element name={section.name}>{section.component}</Element>
            ) : (
              section.component
            )}
          </MotionBox>
        ))}
      </Flex>
      <CallToActionButton />
    </>
  );
};

export default Home;
