import axios from 'axios';
import { useForm } from 'react-hook-form';
import {
  Flex,
  Heading,
  Input,
  Button,
  FormErrorMessage,
  FormControl,
  useToast,
} from '@chakra-ui/react';

import useSpace from '../hooks/useSpace';

const EmailSubscriber = () => {
  const toast = useToast();
  const { space, gutter } = useSpace();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  async function onSubmit(values) {
    const { email } = values;
    try {
      const payload = {
        email_address: email,
      };

      await axios.post('/.netlify/functions/add-email-subscriber', payload);
      reset();
      toast({
        title: 'Subscribed Successfully',
        description: 'GET OUR NEWSLETTER',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      reset();
      toast({
        title: 'Already Subscribed',
        description: `${email} is already a list member`,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
  }

  return (
    <Flex
      w="100%"
      justify="center"
      px={gutter}
      pt={{ base: '32px', lg: '80px' }}
    >
      <Flex w={space} direction="column" align="center">
        <Heading py="40px" fontSize="42px" textTransform="uppercase">
          Get Our Newsletter
        </Heading>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <FormControl htmlFor="email" isInvalid={errors.email} maxW="500px">
            <Input
              placeholder="Enter your email"
              {...register('email', {
                required: 'This is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'invalid email address',
                },
              })}
              py="24px"
              boxShadow="0 0 5px 0 #00ffb0"
              fontSize="20px"
              bg="rgba(0,0,0,0.5)"
              borderColor="green.500"
              _focus={{ boxShadow: '0 0 20px 0 #00ffb0' }}
            />
            <FormErrorMessage>
              {errors.email && errors.email.message}
            </FormErrorMessage>
          </FormControl>
          <Button
            isLoading={isSubmitting}
            type="submit"
            bg="green.500"
            color="black"
            mt="40px"
            border="1px"
            fontSize="20px"
            py="22px"
            px="24px"
            textTransform="uppercase"
            _hover={{
              bg: 'unset',
              border: '1px solid #00FFB0',
              color: 'green.500',
            }}
          >
            Subscribe
          </Button>
        </form>
      </Flex>
    </Flex>
  );
};

export default EmailSubscriber;
