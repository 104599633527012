import ReactGA from 'react-ga4';
import { Flex, Heading, Text, Avatar, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Slick from 'components/Slick';
import useCSV from 'hooks/useCSV';
const slickSettings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 800,
  autoplay: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  onSwipe: () => {
    ReactGA.event({
      category: 'Instructor',
      action: 'InstructorSwipe',
    });
  },
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
      },
    },
  ],
};

const ClassInstructors = ({ csv }) => {
  const { data } = useCSV(csv);

  const { i18n } = useTranslation();
  const isEn = i18n.language === 'en';

  return (
    <Flex
      w={{ base: '90%', lg: '100%' }}
      direction="column"
      py={{ base: '10px', lg: '60px' }}
    >
      <Flex
        direction="column"
        borderLeft="1px"
        borderColor="green.500"
        pl="32px"
        pt="0px"
        mb="30px"
        justify="flex-end"
        align="flex-start"
      >
        <Text fontSize={{ base: '14px', lg: '20px' }}>
          {isEn ? data[0]?.ordinal_en : data[0]?.ordinal_zh}
        </Text>
        <Heading
          color="green.500"
          fontSize={{ base: '20px', lg: '30px' }}
          pt={{ base: '2px', lg: '12px' }}
        >
          {isEn ? data[0]?.course_en : data[0]?.course_zh}
        </Heading>
      </Flex>
      <Slick
        slickSetting={slickSettings}
        style={{
          display: 'flex',
          justify: 'center',
          alignItems: 'stretch',
          position: 'relative',
          width: '100%',
          borderRadius: '5px',
        }}
      >
        {data.map(instructor => (
          <Flex
            h="100%"
            w="100px"
            justify="center"
            px="20px"
            key={instructor.ordinal_en + instructor.course_en}
          >
            <Flex w="100%" align="center" justify="center" direction="column">
              <Link href={instructor.link} isExternal>
                <Avatar
                  w="100px"
                  h="100px"
                  name={instructor.instructor_name_en}
                  src={instructor.avatar}
                />
              </Link>
              <Text
                fontSize="27px"
                pt="20px"
                pb="5px"
                textAlign="center"
                w="200px"
              >
                {/* {t(member.name)} */}
                {isEn
                  ? instructor.instructor_name_en
                  : instructor.instructor_name_zh}
              </Text>
              <Text
                fontSize={{ base: '14px', lg: '16px' }}
                lineHeight={{ base: '20px', lg: '28px' }}
                fontWeight="500"
                textAlign="center"
              >
                {isEn
                  ? instructor.instructor_title_en
                  : instructor.instructor_title_zh}
                {/* {t(member.title)} */}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Slick>
    </Flex>
  );
};

export default ClassInstructors;
