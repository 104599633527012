import { Flex, Heading, Text, Box, chakra, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import useSpace from '../../hooks/useSpace';
import AboutStatusBar from '../AboutStatusBar';
import AboutStatusSlick from '../AboutStatusSlick';
import AboutGrid from '../AboutGrid';
import AboutStudentsFeedback from '../AboutStudentsFeedbacks';
import EmailSubscriber from '../EmailSubscriber';

import aboutPic from '../../assets/images/about_pic.png';
import aboutBg from '../../assets/images/about_bg.png';

const About = () => {
  const { space, spaceY, gutter } = useSpace();
  const { t } = useTranslation();
  const rwdW = {
    base: '100%',
    lg: '50%',
  };
  return (
    <>
      <Flex w="100%" justify="center" py={spaceY} px={gutter}>
        <Flex
          w={space}
          align="center"
          overflow="hidden"
          wrap="wrap"
          direction={{ base: 'column-reverse', lg: 'row' }}
        >
          {/* left */}
          <Flex w={rwdW} direction="column">
            <Heading
              textTransform="uppercase"
              fontSize={{ base: '56px', lg: '90px' }}
              fontWeight="400"
            >
              ABOUT
            </Heading>
            <Heading
              textTransform="uppercase"
              fontSize={{ base: '56px', lg: '80px' }}
              fontWeight="400"
              color="green.500"
            >
              z institute
            </Heading>
            <Flex
              w={{ base: '100%', lg: '85%' }}
              position="relative"
              align="center"
              mt="20px"
            >
              <Box
                position="absolute"
                display={{ base: 'none', lg: 'block' }}
                borderColor="green.500"
                borderWidth="1px"
                h="100%"
                w="9px"
                borderRight="0"
              ></Box>
              <Box
                position="absolute"
                display={{ base: 'none', lg: 'block' }}
                borderColor="green.500"
                borderWidth="1px"
                right="0"
                h="100%"
                w="9px"
                borderLeft="0"
              ></Box>

              <Text py="20px" px={{ base: '0', lg: '50px' }} fontSize="15px">
                The <chakra.span color="green.500">Z Institute </chakra.span>
                {t('about_content')}
              </Text>
            </Flex>
          </Flex>
          {/* right */}
          <Flex
            w={rwdW}
            align="center"
            justify={{ base: 'center', lg: 'flex-end' }}
            py="20px"
          >
            <Image src={aboutPic} w={{ base: '80%', lg: '75%' }} />
          </Flex>
        </Flex>
      </Flex>
      <AboutStatusBar />
      <AboutStatusSlick />
      <Flex
        direction="column"
        w="100%"
        bgImage={`url('${aboutBg}')`}
        bgRepeat="no-repeat"
        bgSize="cover"
        bgPosition="center center"
      >
        <AboutGrid />
        <EmailSubscriber />
        <AboutStudentsFeedback />
      </Flex>
    </>
  );
};

export default About;
