import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Text,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  HStack,
  Stack,
  FormErrorMessage,
  Button,
  Select,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import ContactPlanet from '../ContactPlanet';
import SocialLink from 'components/SocialLink';
import useSpace from '../../hooks/useSpace';

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const toast = useToast();
  const { space, gutter } = useSpace();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({});

  const sendEmail = formData => {
    setIsLoading(true);
    emailjs
      .send(
        'service_xnn8oe3',
        'template_et53ie8',
        formData,
        'lesTciH7FfhqXXITI'
      )
      .then(
        result => {
          console.log(result.text);
          toast({
            title: 'Submitted Successfully',
            description: `We will get back to you soon`,
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
          setIsLoading(false);
          reset();
        },
        error => {
          setIsLoading(false);
          toast({
            title: 'Submitted Failed',
            description: `Please try again`,
            status: 'fail',
            duration: 2000,
            isClosable: true,
          });
        }
      );
  };

  return (
    <Flex
      w="100%"
      py="100px"
      justify="center"
      px={gutter}
      borderTop="1px"
      borderColor="green.500"
    >
      <Flex w={space} wrap="wrap" justify="space-between">
        <Flex
          w={{ base: '100%', lg: '35%' }}
          direction="column"
          pr={{ base: '0', lg: '40px' }}
        >
          <Flex
            w="100%"
            justify="space-between"
            color="green.500"
            fontSize="40px"
            fontWeight="bold"
          >
            <Text textTransform="uppercase">Contact us</Text>
            <Text>→</Text>
          </Flex>
          <Text pt="20px">{t('contact_intro')}</Text>
          <VStack mt="24px" alignItems="flex-start">
            <Text>Email:marketing@zinstitute.net</Text>
            <Text>Tel:0989955446</Text>
            <SocialLink />
          </VStack>
          {/* <ContactPlanet /> */}
        </Flex>
        <Flex w={{ base: '100%', lg: '65%' }} direction="column" pt="40px">
          <form onSubmit={handleSubmit(sendEmail)}>
            <VStack spacing={8}>
              <Stack
                direction={{ base: 'column', lg: 'row' }}
                w="100%"
                spacing={3}
                alignItems="flex-start"
              >
                <FormControl isInvalid={errors.name}>
                  <FormLabel htmlFor="name">{t('contact_name')}</FormLabel>
                  <Input
                    {...register('name', {
                      required: 'This is required',
                    })}
                    placeholder="Name"
                  />
                  <FormErrorMessage>
                    {errors.name && errors.name.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.company}>
                  <FormLabel>{t('contact_title')}</FormLabel>
                  <Input
                    {...register('company', {
                      required: 'This is required',
                    })}
                    placeholder=""
                  />
                  <FormErrorMessage>
                    {errors.company && errors.company.message}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
              <FormControl isInvalid={errors.mail}>
                <FormLabel htmlFor="mail">{t('contact_email')}</FormLabel>
                <Input
                  {...register('mail', {
                    required: 'This is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'invalid email address',
                    },
                  })}
                  placeholder="mail"
                />
                <FormErrorMessage>
                  {errors.mail && errors.mail.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.site}>
                <FormLabel>{t('contact_web')}</FormLabel>
                <Input
                  {...register('site', {
                    required: 'This is required',
                  })}
                  placeholder=""
                />
                <FormErrorMessage>
                  {errors.site && errors.site.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.service}>
                <FormLabel>{t('contact_interest')}</FormLabel>
                <Select
                  {...register('service', {
                    required: 'This is required',
                  })}
                >
                  <option
                    value=""
                    style={{
                      color: 'white',
                      background: 'black',
                    }}
                  >
                    ---
                  </option>
                  <option
                    value={t('contact_smartContract')}
                    style={{
                      color: 'white',
                      background: 'black',
                    }}
                  >
                    {t('contact_smartContract')}
                  </option>
                  <option
                    value={t('contact_dapp')}
                    style={{
                      color: 'white',
                      background: 'black',
                    }}
                  >
                    {t('contact_dapp')}
                  </option>
                  <option
                    value={t('contact_consulting_business')}
                    style={{ color: 'white', background: 'black' }}
                  >
                    {t('contact_consulting_business')}
                  </option>
                  <option
                    value={t('contact_training')}
                    style={{ color: 'white', background: 'black' }}
                  >
                    {t('contact_training')}
                  </option>
                  <option
                    value={t('contact_school')}
                    style={{ color: 'white', background: 'black' }}
                  >
                    {t('contact_school')}
                  </option>
                  <option
                    value={t('contact_project')}
                    style={{ color: 'white', background: 'black' }}
                  >
                    {t('contact_project')}
                  </option>
                  <option
                    value={t('contact_others')}
                    style={{ color: 'white', background: 'black' }}
                  >
                    {t('contact_others')}
                  </option>
                </Select>
                <FormErrorMessage>
                  {errors.service && errors.service.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.content}>
                <FormLabel> {t('contact_detail')}</FormLabel>
                <Textarea
                  rounded="sm"
                  size="sm"
                  resize="none"
                  {...register('content', {
                    required: 'This is required',
                    minLength: {
                      value: 10,
                      message: 'Minimum length should be 10',
                    },
                    maxLength: {
                      value: 200,
                      message: 'Max length should be 200',
                    },
                  })}
                  placeholder={t('contact_detail_placeholder')}
                />
                <FormErrorMessage>
                  {errors.content && errors.content.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
            <Button
              isLoading={isSubmitting || isLoading}
              type="submit"
              bg="green.500"
              color="black"
              mt="40px"
              border="1px"
              fontSize="20px"
              py="22px"
              px="24px"
              textTransform="uppercase"
              _hover={{
                bg: 'unset',
                border: '1px solid #00FFB0',
                color: 'green.500',
              }}
            >
              Submit
            </Button>
          </form>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Contact;
