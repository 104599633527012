import { Flex, Heading, Text, Image } from '@chakra-ui/react';

import WorksHackTheFuture from '../WorksHackTheFuture';
import WorksSlick from '../WorksSlick';
import genesis from '../../assets/images/genesis.png';

const Works = () => {
  return (
    <Flex w="100%" justify="center" direction="column" py="80px">
      <WorksHackTheFuture />
      <Flex direction="column" align="center" pb="40px" px="18px">
        <Heading
          textTransform="uppercase"
          fontSize={{ base: '56px', lg: '90px' }}
        >
          excellent work
        </Heading>
        <Text
          textAlign={{ base: 'left', lg: 'center' }}
          maxW="720px"
          pt={{ base: '20px', lg: '10px' }}
        >
          The Z Institute is dedicated to bring top tier developers into the
          blockchain industry and provide general blockchain education for
          people helping them.
        </Text>
      </Flex>
      <WorksSlick />
      <Image mt={{ base: '60px', lg: '120px' }} src={genesis} w="100%" />
    </Flex>
  );
};

export default Works;
