import { Flex, Text } from '@chakra-ui/react';
// import { useTranslation } from 'react-i18next';

import Slick from './Slick';
import Counter from './Counter';

const slickSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 550,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const AboutStatusSlick = () => {
  // const { i18n } = useTranslation();
  // const isEn = i18n.language === 'en';
  return (
    <Flex
      w="100%"
      h="90px"
      display={{ base: 'flex', lg: 'none' }}
      bg="green.500"
    >
      <Flex w="100%" bg="green.500" color="black" textTransform="uppercase">
        <Slick
          slickSetting={slickSettings}
          style={{
            display: 'flex',
            justify: 'center',
            alignItems: 'stretch',
            position: 'relative',
            width: '100%',
            borderRadius: '5px',
          }}
        >
          <Flex h="100%" w="100%" justify="center" px="20px">
            <Flex h="90px" w="100%" align="center" justify="center">
              <Text fontSize="28px" lineHeight="30px" fontWeight="500">
                Student
                <br />
                member
              </Text>
              <Counter from={0} to={2077} fontSize="60px" />
              <Text alignSelf="flex-end" fontSize="30px" pb="20px" pl="5px">
                ↑
              </Text>
            </Flex>
          </Flex>
          <Flex h="100%" w="100%" justify="center" px="20px">
            <Flex w="100%" h="90px" align="center" justify="center">
              <Text fontSize="30px" lineHeight="30px" fontWeight="500">
                classes
                <br />
                member
              </Text>
              <Counter from={0} to={4} time={0.5} fontSize="60px" />
              <Text alignSelf="flex-end" fontSize="30px" pb="20px" pl="20px">
                ↑
              </Text>
            </Flex>
          </Flex>
          <Flex h="100%" w="100%" justify="center" px="20px">
            <Flex w="100%" h="100%" align="center" justify="space-between">
              <Text fontSize="30px" lineHeight="30px" fontWeight="500">
                NFT
                <br />
                Holder
              </Text>
              <Text fontSize="60px" fontWeight="700" px="5px">
                ???
              </Text>
              {/* <Text alignSelf="flex-end" fontSize="30px" pb="20px" pl="20px">
                ↑
              </Text> */}
            </Flex>
          </Flex>
          <Flex h="100%" w="100%" justify="center" px="20px">
            <Flex w="100%" h="90px" align="center" justify="center">
              <Text fontSize="30px" lineHeight="30px" fontWeight="500">
                partner
                <br />
                company
              </Text>
              <Counter from={0} to={12} time={1} fontSize="60px" />
              <Text alignSelf="flex-end" fontSize="30px" pb="20px" pl="20px">
                ↑
              </Text>
            </Flex>
          </Flex>
        </Slick>
      </Flex>
    </Flex>
  );
};

export default AboutStatusSlick;
