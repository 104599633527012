import { Flex, Heading, Text, Grid, GridItem, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import useSpace from '../hooks/useSpace';
import AboutModal from './AboutModal';

import learnToEarn from '../assets/images/logo/logo_z_bg_black.png';
import topTierLectures from '../assets/images/about/topTierLectures.png';
import deFi from '../assets/images/about/deFi.gif';
import cert from '../assets/images/about/cert.png';
import classroom from '../assets/images/about/classroom.jpg';

const Card = props => {
  const { t } = useTranslation();
  return (
    <Flex
      _hover={{ transform: 'translate(4px, 4px)' }}
      bg="black"
      color="white"
      direction="column"
      transition="all 0.3s"
      h="100%"
      position="relative"
      mx={{ base: '15px', lg: '0' }}
    >
      <Flex
        transition="all 0.3s "
        _hover={{ transform: 'translate(-8px, -8px)' }}
        rounded="xl"
        borderWidth="1px"
        borderColor="green.500"
        h="100%"
        w="100%"
        direction="column"
        px="24px"
        py="24px"
        bg="black"
        position="relative"
      >
        <Heading fontSize={{ base: '16px', lg: '24px' }}>
          {props.children}
        </Heading>
        <Box display={{ base: 'none', lg: 'block' }} pb="20px">
          <Text lineHeight="2" pt="24px" pr="10%" noOfLines={3}>
            {t(props.content)}
          </Text>
        </Box>
        <Flex
          flex="1"
          align="flex-end"
          minH="50px"
          color="green.500"
          position="absolute"
          right="20px"
          bottom="15px"
        >
          <AboutModal
            title={t(props.title)}
            content={t(props.content)}
            image={props.image}
          />
        </Flex>
      </Flex>
      <Box
        position="absolute"
        top="0"
        right="0"
        bg="green.500"
        w="100%"
        h="100%"
        zIndex="-1"
        rounded="xl"
      ></Box>
    </Flex>
  );
};

const AboutGrid = () => {
  const { i18n } = useTranslation();
  const isEn = i18n.language === 'en';
  const { space } = useSpace();
  return (
    <Flex w="100%" justify="center" pt="40px" mt="40px">
      <Grid
        w={space}
        templateColumns={{ base: 'repeat(1,1fr)', lg: 'repeat(3,1fr)' }}
        templateRows={{ base: 'unset', lg: 'repeat(11, 1fr)' }}
        gap={4}
      >
        <GridItem
          area={{ base: 'unset', lg: '1/1/7/2' }}
          colSpan={{ base: 1, lg: 'unset' }}
        >
          <Card
            content="about_LearnToEarn"
            title="about_LearnToEarn_title"
            image={learnToEarn}
          >
            Learn <br /> to <br /> Earn
          </Card>
        </GridItem>
        <GridItem
          area={{ base: 'unset', lg: '7/1/12/2' }}
          colSpan={{ base: 1, lg: 'unset' }}
          h="100%"
        >
          <Card
            content="about_DefiResearchTeam"
            title="about_DefiResearchTeam_title"
            image={deFi}
          >
            {isEn ? (
              <>
                Research
                <br />
                Team
              </>
            ) : (
              <>研究團隊</>
            )}
          </Card>
        </GridItem>
        <GridItem
          area={{ base: 'unset', lg: '1/2/6/3' }}
          colSpan={{ base: 1, lg: 'unset' }}
          h="100%"
        >
          <Card
            content="about_TopTierLecturers"
            title="about_TopTierLecturers_title"
            image={topTierLectures}
          >
            {isEn ? (
              <>
                Top Tier
                <br />
                Lectures
              </>
            ) : (
              <>
                區塊鏈產業
                <br />
                一線講師群
              </>
            )}
          </Card>
        </GridItem>
        <GridItem
          area={{ base: 'unset', lg: '1/3/6/4' }}
          colSpan={{ base: 1, lg: 'unset' }}
          h="100%"
        >
          <Card
            content="about_NFT_Certificate"
            title="about_NFT_Certificate_title"
            image={cert}
          >
            {isEn ? (
              <>
                NFT
                <br />
                Certificate
              </>
            ) : (
              <>
                NFT
                <br />
                畢業證書
              </>
            )}
          </Card>
        </GridItem>
        <GridItem
          area={{ base: 'unset', lg: '6/2/12/4' }}
          colSpan={{ base: 1, lg: 'unset' }}
          h="100%"
        >
          <Card
            content="about_Metaverse_Classroom"
            title="about_Metaverse_Classroom_title"
            image={classroom}
          >
            {isEn ? 'Metaverse Classroom' : '元宇宙教室'}
          </Card>
        </GridItem>
      </Grid>
    </Flex>
  );
};
export default AboutGrid;
