export const translationsEn = {
  about: 'About',
  about_content:
    'is an online blockchain talents incubator and accelerator with a focus of bringing more developers to the space and a blockchain transformer that empowers businesses in technology and research. We offer personalized training and online courses for anyone at any level who wants to join this ecosystem and anyone who loves learning by hacking with innovative blockchain projects.',
  about_LearnToEarn_title: 'Learn to Earn',
  about_LearnToEarn:
    'Students can earn ZI token (issued on the Solana blockchain) by actively participating in the training courses and learning web3 technologies. ',
  about_DefiResearchTeam_title: 'Research Team',
  about_DefiResearchTeam:
    'Composed of our excellent alumnus, research team studies the latest blockchain trends and research topics in the form of weekly study group. Our research fields include DeFi, NFT, and Solidity.',
  about_TopTierLecturers_title: 'Top Tier Lecturers',
  about_TopTierLecturers:
    'Our lecturers are carefully reviewed. Only top-tier blockchain talents are invited to provide the best educational resources to students.',
  about_NFT_Certificate_title: 'NFT Certificate',
  about_NFT_Certificate:
    'Each graduate will receive their own NFT course completion certificate issued on the Solana blockchain. Excellent students will receive special forms of the certificate. ',
  about_Metaverse_Classroom_title: 'Metaverse Classroom',
  about_Metaverse_Classroom:
    'We use interactive online social application "Gather Town" to facilitate online training, simulating in-person and scalable teaching scenarios. Students can easily exchange ideas in the virtual classrooms, conduct group discussions, and interact with the lecturers.',
  //student feedback
  student_feedback_01_name: 'Welly',
  student_feedback_01_content:
    'The course planning and organization is very complete and challenging. You can become a DeFi master if fully absorb the content!',
  student_feedback_02_name: 'Zhang',
  student_feedback_02_content:
    'Thank you for inviting these excellent lecturers. It is non-trivial to be the first one in the educational space yet very valuable. I believe that The Z Institute will become better and better!',
  student_feedback_03_name: 'Emerson Liu',
  student_feedback_03_content:
    'I can feel that the course content is carefully designed, and the invited lecturers are also excellent. It would be even better if the streaming service and discord information organization can be improved. A big thumbs up to the lecturers and crew of The Z Institute!',
  student_feedback_04_name: 'Amanda Liao',
  student_feedback_04_content:
    "Thanks to Z institute's awesome teaching assistants and crew in providing additional learning resources, organizing class notes and answering questions. They are dilligent and attentive to students!",
  student_feedback_05_name: 'Anson Luk',
  student_feedback_05_content:
    'Being able to find people with the same interests and forming a truly active community is highly valuable. I hope Z Institute can continue to thrive!',
  student_feedback_06_name: 'Alex Chiang',
  student_feedback_06_content:
    'Thank you for cultivating Taiwanese blockchain talents and gathering so many outstanding people. Fortunately, our group has a high survival rate and we have learned a lot from our peers!',
  student_feedback_07_name: 'Ken',
  student_feedback_07_content:
    'I learned the general development process of Solidity and I have a better understanding of the industry. I can also really get in touch with people working in the blockchain industry through offline activities, and I am more certain that I really want to be a blockchain engineer in the future!',

  vision:
    'The Z Institute is dedicated to bringing top tier talents into the blockchain industry. Our vision is to become the best Web3 educational institute by helping web2 talents transform into web3.0.',

  // service
  service_01: 'Online Interactive Course',
  service_01_content:
    'The online interactive courses are given by the frontier instructors in Gather Town. In addition, we provide office hours and real time AZA (ask Z anything) on Discord so that the students will have the best online learning environment.',
  service_02: 'Blockchain Consultant Service',
  service_02_content:
    'Provide SMEs blockchain transformation consulting services, including application design, industrial research, underlying architecture, etc.',
  service_03: 'Security Audit',
  service_03_content:
    'Experienced and professional blockchain security audits in Solidity and Rust.',
  service_04: 'Job Matching',
  service_04_content:
    'Cooperating with top tier blockchain companies to provide international job matching for students. We have held Solidity Career Fair, and participating companies include well-known blockchain companies such as PureStake and Impossible Finance.',
  service_05: 'Customized smart contracts and tandem services',
  service_05_content: 'Can provide more customized smart contract services.',

  //course
  course_action: 'see all courses',

  //achieved
  achieved_title: 'What we have achieved',
  achieved_01: 'The Z Institute established',
  achieved_02: 'First batch of DeFi Talents Incubator started',
  achieved_03: 'Solidity Smart Contract Developer Bootcamp started',
  achieved_03_02: 'Security Audit - Stader Labs ',
  achieved_04_01: 'Second batch of DeFi Incubator started',
  achieved_04_02: 'Development - Opyn Polygon Integration',
  achieved_05_01: 'First batch of NFT & GameFi Talents Incubator started',
  achieved_05_02: 'Security Audit - Edge Protocol	',

  //roadmap
  roadmap_2021_Q4_1: 'Bootcamp courses begin',
  roadmap_2022_Q1_1: 'Online pre-recorded courses planing',
  roadmap_2022_Q1_2: 'Establishes office hour mechanism',
  roadmap_2022_Q2_1: 'Release syllabus of the online courses',
  roadmap_2022_Q2_2: 'Release new bootcamp courses:',
  roadmap_2022_Q2_2_1:
    'Security Bootcamp, Technical Writer, DeFi, NFT & GameFi, Solidity, Solana, etc',
  roadmap_2022_Q3_1: 'Bootcamp course begins',
  roadmap_2022_Q3_2: 'Releases the Beta version of online courses',
  roadmap_2022_Q4_1: 'Regularly releases tutorial articles',
  roadmap_2022_Q4_2: 'Regularly uploads YouTube videos',
  roadmap_2022_Q4_3: 'Release most of the online courses',
  roadmap_2022_Q4_4:
    'Launch bootcamp courses according to the latest topics and technology trends.',

  //instructor
  first: '1st',
  second: '2nd',
  instructors_01: 'DeFi Incubator',
  instructors_01_teacher_01_name: 'Alvin',
  instructors_01_teacher_01_title:
    'First batch of DeFi Incubator extinguished alumni',
  instructors_01_teacher_02_name: 'Reyer Chu',
  instructors_01_teacher_02_title:
    'Founder of deFintek & Taiwan DeFi Study Group',
  instructors_01_teacher_03_name: 'Tom',
  instructors_01_teacher_03_title: 'Economist of LikeCoin',
  instructors_01_teacher_04_name: 'Zoni',
  instructors_01_teacher_04_title: 'Researcher at Footprint',
  instructors_01_teacher_05_name: 'Tina',
  instructors_01_teacher_05_title:
    'Founder of The Z Institute, blockchain researcher and security auditor since 2017',

  instructors_01_teacher_06_name: 'Anderson Chen',
  instructors_01_teacher_06_title:
    'Co-Founder & CTO at Coinomo, Founder & CEO at Dapp Pocket',
  //NFT & GameFi
  instructors_02: 'NFT & GameFi Incubator',
  instructors_02_teacher_01_name: 'Swanky Hsiao',
  instructors_02_teacher_01_title:
    'International Trust Machines Corporation advisor、Soochow University assistant professor',
  instructors_02_teacher_02_name: 'Ju-Chun Ko',
  instructors_02_teacher_02_title:
    'Assistant Professor @ NTUT、Founder of Memora & Luna ',
  instructors_02_teacher_03_name: 'Eason Hu',
  instructors_02_teacher_03_title:
    'Marketing Director of RE:DREAMER, Evangelist of Fansi',
  instructors_02_teacher_04_name: 'Che-Yu Wu',
  instructors_02_teacher_04_title:
    'Founder of Monoame Design & CryptoPochi, NFT generative artist',
  instructors_02_teacher_05_name: 'Tina',
  instructors_02_teacher_05_title:
    'Founder of The Z Institute, blockchain researcher and security auditor since 2017',
  instructors_02_teacher_06_name: 'Aluan',
  instructors_02_teacher_06_title: 'The first Taiwanese to be on ArtBlocks',
  instructors_02_teacher_07_name: 'Mu-Tien Chen',
  instructors_02_teacher_07_title:
    'Founder of aestheticell textbook、Bank Of Culture，Sponsor of Rug Pull Frens',
  instructors_02_teacher_08_name: 'Dao Dao',
  instructors_02_teacher_08_title: 'YouTuber、Founder of Yuubuke&YuuSchool',
  instructors_02_teacher_09_name: 'Ricky',
  instructors_02_teacher_09_title: 'Founder of TomoTouch',
  instructors_02_teacher_10_name: 'ZhouYongyou',
  instructors_02_teacher_10_title:
    'NFT artist and collector who has a number of KGFs',
  instructors_03: 'Smart Contract Development Class',
  instructors_03_teacher_02_name: 'Justa',
  instructors_03_teacher_02_title: 'Developer of MetaJungle ',
  instructors_03_teacher_03_name: 'Jim',
  instructors_03_teacher_03_title: 'Tech Lead of Dappio',
  instructors_03_teacher_04_name: 'Wei',
  instructors_03_teacher_04_title: 'Developer of Dappio',
  instructors_03_teacher_05_name: 'Tim',
  instructors_03_teacher_05_title: 'Developer of Dappio',
  instructors_03_teacher_06_name: 'Jeffrey',
  instructors_03_teacher_06_title: 'Founder of Rebirth-NFT',
  instructors_03_teacher_07_name: 'Justin Starry',
  instructors_03_teacher_07_title: 'Solana Core Engineer',
  instructors_03_teacher_08_name: 'CopyCat',
  instructors_03_teacher_08_title: 'Solana Chinese community administrator',
  instructors_03_teacher_09_name: 'Kazami',
  instructors_03_teacher_09_title: 'Blockchain evangelist / Data scientist',
  instructors_03_teacher_01_name: 'Tina',
  instructors_03_teacher_01_title:
    'Founder of the Z Institute, blockchain researcher and security auditor since 2017',
  //partner
  partner_subtitle:
    'We work with our partners to provide services including smart contract development, security audits, in-house training and talents matching.',
  //QA
  q1: 'Will Z Institute provide face to face courses in the future or all courses would be conducted online?',
  a1: 'We currently only provide online courses and we would provide face to face classes in the near future. Stay tuned!',
  q2: 'Will Z Institute provide recording and materials of classes for students?',
  a2: 'Every recording would be provided to students. However, we still recommend students to join the online class in person to interact directly with the lecturer.',
  q3: ' Can we access course material after graduation?',
  a3: 'Yes. Students can review course materials anytime.',
  q4: ' How should I pay the tuition fee?',
  a4: 'You can choose to pay via bank transfer or stable coin such as USDC. We will send you a confirmation email afterward.',

  //news
  news_01_title: 'ZNFT：Metaverse University Pass',
  news_01_content: '',
  news_02_title:
    'Asia Blockchain Media report ：The first smart contract engineer job matchmaking event in Taiwan',
  news_02_content: '',
  news_03_title:
    'Asia Blockchain Media report ：ERC721R Smart Contract Practical Test',
  news_03_content: '',

  //contact
  contact_intro: `The Z Institute provides professional blockchain consultants and customized development. We have profound development experience in EVM-compatible blockchains such as Ethereum, Binance Smart Chain, EOS, and Solana. Includes NFTs, games, token contracts, etc. We accept anonymous developement and 100% crypto currency payment. We also cooperate with lawyers to deal with crypto currency's legal issue. `,
  contact_name: 'Full name',
  contact_email: 'Email address',
  contact_title: 'Company or organization/Title',
  contact_web: `Company or organization's website`,
  contact_interest: 'Consultation interest',
  contact_smartContract: 'Smart contract development',
  contact_dapp: 'Decentralized application development',
  contact_consulting_business: 'Blockchain solution consulting for business',
  contact_training: 'Blockchain training for business',
  contact_school: 'Blockchain courses for school',
  contact_project: `Blockchain project's cooperative promotion`,
  contact_others: 'Others',
  contact_detail: 'Consultation details',
  contact_detail_placeholder:
    'Please fill your requirement in details to help us understand and solve your problems in a faster way ! For course requirements, please provide the subject name, skill range, background and number of students, online or offline, etc.',

  //footer
  footer_about_01: 'About us',
  footer_about_02: 'Join us',
  footer_about_03: 'Consumer Protection Clause',
  footer_about_04: 'Copyright Policy',
  footer_about_05: 'Privacy Policy',
  footer_help_01: 'Enterprise Learning Program',
  footer_help_02: 'Enterprise cooperation negotiation',
};
