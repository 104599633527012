import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Button,
  Text,
  HStack,
  Image,
  Flex,
} from '@chakra-ui/react';

import logoZ from '../assets/images/logo/logo_z.svg';
import modalClose from '../assets/images/modal_close.svg';

const AboutModal = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { title, content, image } = props;

  useEffect(() => {
    if (isOpen) {
      ReactGA.event({
        category: 'AboutModal',
        action: `modal_open_${title}`,
      });
      console.log('isOpen', isOpen, title);
    }
  }, [isOpen, title]);

  return (
    <>
      <Button
        fontSize={{ base: '12px', lg: '16px' }}
        px="0"
        onClick={onOpen}
        bg="none"
        _hover={{
          bg: 'none',
          borderBottom: '1px solid green.500',
        }}
        _active={{
          border: 'none',
          shadow: 'none',
        }}
        _focus={{
          border: 'none',
          shadow: 'none',
        }}
      >
        READ MORE →
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} w="auto">
        <ModalOverlay />
        <ModalContent
          bg="black"
          border="1px"
          borderColor="green.500"
          h="600px"
          w="1000px"
          maxW="unset"
          mx="10px"
        >
          <Flex
            w="100%"
            h="36px"
            align="center"
            borderBottom="1px"
            borderColor="green.500"
            justify="space-between"
            px="20px"
          >
            <HStack flex="1">
              <Image src={logoZ} h="12px" />
              <Text fontSize="15px" color="green.500" whiteSpace="noWrap">
                {title}
              </Text>
            </HStack>
            <Image src={modalClose} onClick={onClose} cursor="pointer" />
            <HStack></HStack>
          </Flex>
          {/* </ModalHeader> */}
          <ModalBody display="flex" flexDirection="column">
            <Text textTransform="uppercase" fontSize="30px" color="green.500">
              {title}
            </Text>
            <Flex
              direction={{ base: 'column', lg: 'row' }}
              align="center"
              flex="1"
            >
              <Flex
                align="center"
                justify="center"
                w={{ base: '100%', lg: '50%' }}
              >
                <Text fontSize="15px" w="100%" px="20px" py="20px">
                  {content}
                </Text>
              </Flex>
              <Flex w={{ base: '100%', lg: '50%' }}>
                <Image
                  rounded="xl"
                  w="100%"
                  src={image}
                  h="100%"
                  maxH="300px"
                  objectFit="contain"
                />
              </Flex>
            </Flex>
            {/* <Flex>
              <Button
                bg="sun"
                mr={3}
                mb="15px"
                onClick={onClose}
                _focus={{ shadow: 'unset' }}
                color="green.500"
                _hover={{ bg: 'green.500', color: 'black' }}
              >
                CLOSE →
              </Button>
            </Flex> */}
          </ModalBody>
          {/* <ModalFooter>
            <Button bg="sun" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default AboutModal;
