import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import Slick from '../Slick';

import { Flex, Heading, Grid, GridItem, Link } from '@chakra-ui/react';

import useSpace from '../../hooks/useSpace';
import CourseCalendarCard from '../CourseCalendarCard';

import { Pickaxe, Sword, FishingSword, Shovel, Ax, Bread } from '../icons';

const formLink = 'https://forms.gle/hStDkWzitpem1YNA9';

const courses = [
  {
    name: 'DEFI',
    image: Pickaxe,
    content: (
      <>
        DeFi Talents
        <br />
        Incubator
      </>
    ),
    contentZh: (
      <>
        DeFi
        <br />
        實戰人才加速器
      </>
    ),
    link: 'https://courses.zinstitute.net/courses/defi-b4-s',
  },
  {
    name: 'NFT',
    image: Sword,
    content: (
      <>
        NFT & GameFi
        <br />
        Talents Incubator
      </>
    ),
    contentZh: (
      <>
        NFT & GameFi
        <br />
        實戰人才加速器
      </>
    ),
    link: formLink,
  },
  {
    name: 'Web3Front-end',
    image: Ax,
    content: (
      <>
        Web 3
        <br />
        Front-end
        <br />
        development
        <br />
        class
      </>
    ),
    contentZh: (
      <>
        給前端的
        <br />
        Web 3 開發班
      </>
    ),
    link: 'https://zinstitute.kaik.io/courses/web3-0',
  },
  {
    name: 'Solidity',
    image: FishingSword,
    content: (
      <>
        Solidity
        <br />
        Smart Contract
        <br />
        Development Bootcamp
      </>
    ),
    contentZh: (
      <>
        Solidity
        <br />
        智能合約開發班
      </>
    ),
    link: formLink,
  },
  {
    name: 'Solana',
    image: Shovel,
    content: (
      <>
        Solana
        <br />
        Development
        <br />
        Bootcamp
      </>
    ),
    contentZh: (
      <>
        Solana
        <br />
        開發者培訓班
      </>
    ),
    link: formLink,
  },

  {
    name: 'Web3Pre',
    image: Bread,
    content: (
      <>
        web 3
        <br />
        pre-recorded courses
      </>
    ),
    contentZh: (
      <>
        web 3
        <br />
        初階預錄課程
      </>
    ),
    link: 'https://zinstitute.kaik.io/categories/web3',
  },
];

const slickSettings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 800,
  autoplay: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  onSwipe: () => {
    ReactGA.event({
      category: 'CourseCalendar',
      action: 'CourseSwipe',
    });
  },
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
      },
    },
  ],
};

const CourseCalendar = () => {
  const { space } = useSpace();

  const { t } = useTranslation();

  return (
    <Flex
      w="100%"
      justify="center"
      overflow="hidden"
      py={{ base: '40px', lg: '100px' }}
      direction="column"
      align="center"
    >
      {/* Heading */}
      <Flex
        px="15px"
        w={{ base: '100%', lg: '80%' }}
        maxW="1250px"
        direction="column"
      >
        <Heading
          fontSize={{ base: '60px', lg: '90px' }}
          textAlign="left"
          fontWeight="500"
          color="green.500"
          pb="50px"
        >
          COURSE <br /> CALENDAR
        </Heading>
      </Flex>
      <Flex w={space} display={{ base: 'none', lg: 'flex' }}>
        <Slick
          slickSetting={slickSettings}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'stretch',
            position: 'relative',
            width: '100%',
            padding: '0px 30px',
            borderRadius: '5px',
          }}
        >
          {courses.map((course, index) => (
            <CourseCalendarCard
              key={course.name + index}
              {...course}
              isOdd={(index + 1) % 2 === 1}
            />
          ))}
        </Slick>
      </Flex>

      <Flex
        display={{ base: 'flex', lg: 'none' }}
        px="15px"
        w={{ base: '100%', lg: '80%' }}
        maxW="1250px"
        direction="column"
      >
        <Grid
          w="100%"
          templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(4, 1fr)' }}
          gap={5}
        >
          {courses.map((course, index) => (
            <GridItem key={course.name}>
              <CourseCalendarCard
                display={{ base: 'flex', lg: 'none' }}
                {...course}
                isOdd={(index + 1) % 2 === 1}
              />
            </GridItem>
          ))}
        </Grid>
      </Flex>

      <Flex p="40px" w={space} direction="column">
        <Link
          href="https://zinstitute.kaik.io/"
          textTransform="uppercase"
          fontSize="20px"
          isExternal
        >
          {t('course_action')} →
        </Link>
      </Flex>
    </Flex>
  );
};

export default CourseCalendar;
