import { useState, useRef } from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const MotionFlex = motion(Flex);
const MotionBox = motion(Box);
const MotionText = motion(Text);

function getRelativeCoordinates(event, referenceElement) {
  const position = {
    x: event.pageX,
    y: event.pageY,
  };

  const offset = {
    left: referenceElement.offsetLeft,
    top: referenceElement.offsetTop,
    width: referenceElement.clientWidth,
    height: referenceElement.clientHeight,
  };

  let reference = referenceElement.offsetParent;

  while (reference) {
    offset.left += reference.offsetLeft;
    offset.top += reference.offsetTop;
    reference = reference.offsetParent;
  }

  return {
    x: position.x - offset.left,
    y: position.y - offset.top,
    width: offset.width,
    height: offset.height,
    centerX: (position.x - offset.left - offset.width / 2) / (offset.width / 2),
    centerY:
      (position.y - offset.top - offset.height / 2) / (offset.height / 2),
  };
}

const Coordinates = props => {
  let { mousePosition } = props;
  return (
    <>
      <MotionBox
        // display={{ base: 'none', lg: 'block' }}
        position="absolute"
        w="1px"
        h="100vh"
        opacity={0.3}
        left="0"
        animate={{
          x: mousePosition.x,
        }}
      >
        <Box w="8px" borderRight="1px" borderColor="green.500" h="100%"></Box>
      </MotionBox>
      <MotionBox
        position="absolute"
        // display={{ base: 'none', lg: 'block' }}
        opacity={0.3}
        w="100vw"
        h="1px"
        // bg="green.500"
        top="0"
        left="0"
        animate={{
          y: mousePosition.y,
          // y: mousePosition.y,
        }}
      >
        <Box h="8px" borderBottom="1px" borderColor="green.500" w="100%"></Box>
      </MotionBox>
      <MotionFlex
        // display={{ base: 'none', lg: 'flex' }}
        position="absolute"
        w="14px"
        h="14px"
        rounded="full"
        borderColor="green.500"
        borderWidth="1px"
        bg="black"
        align="center"
        justify="center"
        top="0"
        left="0"
        animate={{
          x: mousePosition.x,
          y: mousePosition.y,
        }}
      >
        <Box w="6px" h="6px" rounded="full" bg="green.500"></Box>
      </MotionFlex>
    </>
  );
};

const Matrix = () => {
  return (
    <>
      <MotionFlex
        position="absolute"
        left="10%"
        top="120px"
        initial={{ rotate: '90deg' }}
        animate={{ y: '75vh', rotate: '90deg' }}
        transformOrigin="top left"
        transition={{
          repeat: Infinity,
          ease: 'linear',
          duration: 3.5,
          repeatDelay: 0.4,
        }}
      >
        <Text textTransform="uppercase" color="green.500" fontSize="16px">
          HACK THE ENLIGHTEN THE FUTURE
        </Text>
        <Box
          position="absolute"
          w="100%"
          h="100%"
          bgGradient="linear(to-r, blackO.900, blackO.100)"
        ></Box>
      </MotionFlex>
      <MotionFlex
        position="absolute"
        left="15%"
        top="-100px"
        initial={{ rotate: '90deg' }}
        animate={{ y: '95vh', rotate: '90deg' }}
        transformOrigin="top left"
        transition={{
          repeat: Infinity,
          ease: 'linear',
          duration: 3.7,
          repeatDelay: 0.5,
        }}
      >
        <Text textTransform="uppercase" color="green.500" fontSize="16px">
          Hack the future. Enlighten the future.
        </Text>
        <Box
          position="absolute"
          w="100%"
          h="100%"
          bgGradient="linear(to-r, blackO.900, blackO.100)"
        ></Box>
      </MotionFlex>

      {/* right side */}
      <MotionFlex
        initial={{ rotate: '90deg' }}
        animate={{ y: '75vh', rotate: '90deg' }}
        transformOrigin="bottom right"
        position="absolute"
        right="10%"
        top="320px"
        transition={{
          repeat: Infinity,
          ease: 'linear',
          duration: 3.5,
          repeatDelay: 0.4,
        }}
      >
        <Text textTransform="uppercase" color="green.500" fontSize="16px">
          HACK THE ENLIGHTEN THE FUTURE
        </Text>
        <Box
          position="absolute"
          w="100%"
          h="100%"
          bgGradient="linear(to-r, blackO.900, blackO.100)"
        ></Box>
      </MotionFlex>
      <MotionFlex
        position="absolute"
        right="15%"
        top="200px"
        initial={{ rotate: '90deg' }}
        animate={{ y: '95vh', rotate: '90deg' }}
        transformOrigin="bottom right"
        transition={{
          repeat: Infinity,
          ease: 'linear',
          duration: 3.7,
          repeatDelay: 0.5,
        }}
      >
        <Text textTransform="uppercase" color="green.500" fontSize="16px">
          Hack the future. Enlighten the future.
        </Text>
        <Box
          position="absolute"
          w="100%"
          h="100%"
          bgGradient="linear(to-r, blackO.900, blackO.100)"
        ></Box>
      </MotionFlex>
    </>
  );
};

const TextAnimation = () => {
  const textVariants = {
    offscreen: {
      y: -50,
      opacity: 0,
    },
    onscreen: i => ({
      y: 0,
      // rotate: -10,
      opacity: 1,
      transition: {
        // type: 'spring',
        // bounce: 0.4,
        duration: 0.5,
        delay: i * 0.5,
      },
    }),
  };

  const futureVariants = {
    offscreen: {
      y: -50,
      opacity: 0,
    },
    onscreen: {
      y: [-50, 0, 0, 0, 0],
      opacity: [0, 1, 1, 1, 1],
      x: [0, 0, 0, 485, 435],
      color: ['#fff', '#fff', '#fff', '#fff', '#00FFB0'],
      letterSpacing: ['0px', '0px', '0px', '20px', '30px'],
      transition: {
        duration: 3,
        delay: 0.75,
        times: [0, 0.3, 0.5, 0.7, 1],
      },
    },
  };
  return (
    <MotionFlex
      // w="800px"
      w={{ base: '90%', lg: '800px' }}
      h="320px"
      direction="column"
      fontSize={{ base: '20px', lg: '60px' }}
      lineHeight="80px"
      whileInView="onscreen"
      initial="offscreen"
      zIndex="1"
    >
      <Flex w="100%" justify="space-between">
        {/* left top */}
        <Flex direction="column">
          <MotionText variants={textVariants} custom={0}>
            HACK
          </MotionText>
          <MotionText variants={textVariants} custom={1}>
            THE
          </MotionText>
        </Flex>
        {/* right top */}
        <Flex direction="column" textAlign="right">
          <MotionText variants={textVariants} custom={3}>
            Enlighten
          </MotionText>
          <MotionText variants={textVariants} custom={4}>
            THE
          </MotionText>
        </Flex>
      </Flex>
      {/* bottom */}
      <Flex w="100%" position="relative">
        <MotionText
          position="absolute"
          top="0"
          w="100%"
          variants={futureVariants}
        >
          FUTURE
        </MotionText>
      </Flex>
    </MotionFlex>
  );
};

const MobileText = () => {
  return (
    <Flex
      display={{ base: 'flex', lg: 'none' }}
      fontSize="48px"
      direction="column"
      px="20px"
      zIndex="10"
    >
      <Text>HACK</Text>
      <Text>THE</Text>
      <Text color="green.500">FUTURE</Text>
    </Flex>
  );
};

const HackTheFuture = () => {
  const [mousePosition, setMousePosition] = useState({
    x: 280,
    y: 300,
  });
  const sectionRef = useRef();
  const handleMouseMove = e => {
    if (window.screen.width > 768) {
      setMousePosition(getRelativeCoordinates(e, sectionRef.current));
    }
  };

  return (
    <MotionFlex
      cursor="none"
      ref={sectionRef}
      borderWidth="1px"
      borderColor="green.500"
      borderRight="none"
      borderLeft="none"
      h={{ base: '400px', lg: '65vh' }}
      minH={{ lg: '800px' }}
      w="100%"
      align="center"
      justify={{ base: 'flex-start', lg: 'center' }}
      onMouseMove={handleMouseMove}
      position="relative"
      overflow="hidden"
      style={{ perspective: 600 }}
      animate={{
        rotateX: mousePosition.centerX * 20,
        rotateY: mousePosition.centerY * 20,
      }}
    >
      {/* <Box>
        {mousePosition.x + 2} / {mousePosition.y}
      </Box> */}
      {/* Hack the future. Enlighten the future. */}
      <Matrix />
      <Coordinates mousePosition={mousePosition} />
      <Box display={{ base: 'none', lg: 'block' }}>
        <TextAnimation />
      </Box>
      <MobileText />
    </MotionFlex>
  );
};

export default HackTheFuture;
