import { Flex, Box, Heading, Stack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import useSpace from '../../hooks/useSpace';
import bgNumber from '../../assets/images/bg_number.svg';
import RoadmapCard from '../RoadmapCard';

const MotionBox = motion(Box);

const roadmap2021Q4 = {
  quarter: 'Q4',
  year: '2021',
  isDone: true,
  isProcess: false,
  title: ['roadmap_2021_Q4_1'],
  content: [],
};

const roadmap2022Q1 = {
  quarter: 'Q1',
  year: '2022',
  isDone: true,
  isProcess: false,
  title: ['roadmap_2022_Q1_1', 'roadmap_2022_Q1_2'],
  content: [],
};

const roadmap2022Q2 = {
  quarter: 'Q2',
  year: '2022',
  isDone: false,
  isProcess: true,
  title: ['roadmap_2022_Q2_1', 'roadmap_2022_Q2_2'],
  content: ['roadmap_2022_Q2_2_1'],
};

const roadmap2022Q3 = {
  quarter: 'Q3',
  year: '2022',
  isDone: false,
  isProcess: false,
  title: ['roadmap_2022_Q3_1'],
  content: ['roadmap_2022_Q3_2'],
};

const roadmap2022Q4 = {
  quarter: 'Q4',
  year: '2022',
  isDone: false,
  isProcess: false,
  title: ['roadmap_2022_Q4_1', 'roadmap_2022_Q4_2', 'roadmap_2022_Q4_3'],
  content: ['roadmap_2022_Q4_4'],
};

const RoadMap = () => {
  const variants = {
    visible: {
      opacity: 0.185,
      transition: {
        duration: 1.5,
        ease: 'easeInOut',
        repeat: Infinity,
        repeatType: 'reverse',
      },
    },
    hidden: { opacity: 0.05 },
  };
  const { space } = useSpace();
  return (
    <Flex
      id="roadmap"
      w="100%"
      py={{ base: '50px', lg: '150px' }}
      borderTop="1px"
      borderBottom="1px"
      borderColor="green.500"
      align="center"
      justify="center"
      position="relative"
      direction="column"
    >
      <MotionBox
        bgImage={`url('${bgNumber}')`}
        opacity="0.2"
        position="absolute"
        w="100%"
        h="100%"
        initial="hidden"
        animate="visible"
        variants={variants}
        zIndex="-1"
      ></MotionBox>
      <Heading
        fontWeight="500"
        textTransform="uppercase"
        fontSize={{ base: '56px', lg: '90px' }}
        pb="20px"
        color="green.500"
      >
        RoadMap
      </Heading>
      <Flex
        bg="black"
        w={space}
        px="18px"
        align="center"
        justify="center"
        rounded="lg"
        direction="column"
      >
        <Stack
          w="100%"
          direction={{ base: 'column', lg: 'row' }}
          spacing="20px"
          pb="20px"
        >
          <RoadmapCard content={roadmap2021Q4} />
          <RoadmapCard content={roadmap2022Q1} />
        </Stack>
        <RoadmapCard content={roadmap2022Q2} />
        <Stack
          w="100%"
          direction={{ base: 'column', lg: 'row' }}
          spacing="20px"
          pt="20px"
        >
          <RoadmapCard content={roadmap2022Q3} />
          <RoadmapCard content={roadmap2022Q4} />
        </Stack>
      </Flex>
    </Flex>
  );
};

export default RoadMap;
