import { Link, Button } from '@chakra-ui/react';
const CallToActionButton = () => {
  return (
    <Link href="https://courses.zinstitute.net/courses/web3frontend" isExternal>
      <Button
        position="fixed"
        bottom={{ base: '50px', lg: '40px' }}
        right={{ base: '0px', lg: '32px' }}
        shadow="md"
        bg="green.500"
        color="black"
        mt="40px"
        border="1px"
        fontSize="20px"
        py="22px"
        px="24px"
        textTransform="uppercase"
        _hover={{
          bg: 'black',
          border: '1px solid #00FFB0',
          color: 'green.500',
        }}
      >
        立即報名 前端工程師 Web 3 開發實戰班
      </Button>
    </Link>
  );
};

export default CallToActionButton;
