import { Icon } from '@chakra-ui/icons';

export const Bread = props => {
  return (
    <Icon viewBox="0 0 150 150" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="104.528"
        height="104.521"
        viewBox="0 0 104.528 104.521"
      >
        <g id="rfxTKN.tif" transform="translate(-5029.257 2888.664)">
          <g
            id="Group_562"
            data-name="Group 562"
            transform="translate(5029.257 -2888.665)"
          >
            <path
              id="Path_24513"
              data-name="Path 24513"
              d="M5321.869-2888.665h17.96c.667,0,.667,0,.668.676,0,2.082.009,4.164-.008,6.245,0,.389.108.516.5.513,2.122-.017,4.245-.009,6.368-.007.56,0,.562,0,.562.575,0,2.15-.01,4.3.009,6.449,0,.422-.14.532-.541.529q-3.224-.023-6.449,0c-.386,0-.52-.115-.517-.511.016-2.149,0-4.3.016-6.449,0-.386-.126-.48-.492-.48q-18.1.011-36.206,0c-.374,0-.489.108-.486.486.017,2.123.008,4.245.008,6.367,0,.578,0,.58-.6.58-2.082,0-4.164.009-6.245-.007-.4,0-.553.093-.55.521.019,2.122.007,4.245.01,6.367,0,.5.025.526.536.527h13.592c.681,0,.681,0,.681.663,0,2.082.011,4.163-.009,6.245,0,.424.139.527.54.524,2.109-.017,4.218-.009,6.327-.007.564,0,.57.009.57.57,0,2.15-.009,4.3.009,6.449,0,.416-.128.534-.536.531q-3.245-.026-6.49,0c-.432,0-.526-.164-.524-.551.012-2.109.006-4.218,0-6.326,0-.558,0-.56-.573-.56h-13.634c-.6,0-.6,0-.6-.607,0-2.109-.01-4.218.008-6.327,0-.4-.124-.5-.51-.5q-6.94.018-13.877,0c-.387,0-.516.1-.512.5.018,2.136,0,4.272.017,6.408,0,.409-.116.538-.531.534-2.136-.018-4.272,0-6.408-.017-.4,0-.506.116-.505.508q.019,6.877.008,13.755c0,.6,0,.6-.614.6-2.137,0-4.272-.009-6.409.008-.4,0-.519-.129-.518-.515q.014-6.98,0-13.959c0-.4.142-.512.525-.509,2.1.014,4.19.007,6.286.007.618,0,.619,0,.619-.6q0-3.123,0-6.245c0-.565.01-.575.566-.575q3.163,0,6.327,0c.512,0,.53-.018.53-.529q0-3.163,0-6.326c0-.555.013-.568.574-.568h13.8c.625,0,.626,0,.626-.628q0-3.163,0-6.327c0-.569,0-.574.563-.574q3.163,0,6.327,0c.537,0,.539,0,.539-.544q0-3.1,0-6.2c0-.676,0-.677.666-.677Z"
              transform="translate(-5250.97 2888.665)"
              fill="currentColor"
            />
            <path
              id="Path_24514"
              data-name="Path 24514"
              d="M5280.984-1353.655h14.363c.614,0,.616,0,.617.607q0,3.2,0,6.406c0,.506-.027.532-.528.532h-28.89c-.508,0-.534-.024-.534-.526q0-3.223,0-6.447c0-.562.011-.572.57-.572Z"
              transform="translate(-5251.052 1450.631)"
              fill="currentColor"
            />
            <path
              id="Path_24515"
              data-name="Path 24515"
              d="M6571.9-2637q0,7.122,0,14.245c0,.644,0,.645-.659.645h-6.327c-.557,0-.559,0-.559-.574q0-14.347,0-28.694c0-.53.016-.547.553-.547q3.225,0,6.449,0c.523,0,.543.022.543.559Q6571.9-2644.18,6571.9-2637Z"
              transform="translate(-6467.371 2666.88)"
              fill="currentColor"
            />
            <path
              id="Path_24516"
              data-name="Path 24516"
              d="M5029.258-1814.165q0-5.306,0-10.611c0-.6,0-.6.582-.6h6.367c.6,0,.6,0,.6.62q0,10.51,0,21.019c0,.644,0,.644.64.644h6.245c.535,0,.542.006.542.552,0,2.136-.008,4.272.008,6.408,0,.4-.112.542-.531.539q-3.245-.028-6.49,0c-.409,0-.544-.117-.541-.531.017-2.095.008-4.19.008-6.285,0-.569,0-.574-.564-.574h-6.286c-.56,0-.576-.016-.576-.567Q5029.257-1808.86,5029.258-1814.165Z"
              transform="translate(-5029.258 1892.551)"
              fill="currentColor"
            />
            <path
              id="Path_24517"
              data-name="Path 24517"
              d="M5029.257-2164.768q0-3.427,0-6.855c0-.56,0-.563.575-.563,2.122,0,4.244-.008,6.366.007.372,0,.495-.1.492-.482-.015-2.135-.008-4.271-.006-6.406,0-.537.011-.546.549-.547,2.163,0,4.326.008,6.488-.008.391,0,.518.121.515.512q-.021,3.244,0,6.488c0,.4-.14.507-.522.5-2.108-.015-4.217-.007-6.325-.007-.586,0-.587,0-.587.574q0,6.835,0,13.669c0,.611,0,.612-.6.612h-6.325c-.615,0-.617,0-.617-.6Q5029.256-2161.321,5029.257-2164.768Z"
              transform="translate(-5029.257 2224.416)"
              fill="currentColor"
            />
            <path
              id="Path_24518"
              data-name="Path 24518"
              d="M6213.841-2157.291c-1.088,0-2.177-.014-3.264.008-.384.007-.521-.1-.518-.5q.024-3.264,0-6.529c0-.4.133-.518.517-.516,2.108.012,4.217.006,6.325.005.593,0,.594,0,.594-.578,0-2.095.011-4.189-.008-6.284,0-.417.128-.531.536-.528,2.135.018,4.271,0,6.406.017.4,0,.506-.119.5-.509-.017-2.135,0-4.271-.017-6.406,0-.414.121-.533.532-.53q3.224.027,6.448,0c.416,0,.532.125.529.533q-.028,3.223,0,6.447c0,.418-.128.53-.534.526-2.095-.018-4.189-.008-6.285-.008-.571,0-.573,0-.573.558q0,3.142,0,6.284c0,.584,0,.585-.594.585-2.108,0-4.217.009-6.325-.008-.395,0-.515.121-.512.511.015,2.108.007,4.217.007,6.325,0,.6,0,.6-.581.6Z"
              transform="translate(-6135.46 2224.438)"
              fill="currentColor"
            />
            <path
              id="Path_24519"
              data-name="Path 24519"
              d="M5756.915-1707.808q1.611,0,3.223,0c.533,0,.536,0,.536.552q0,3.2,0,6.406c0,.576,0,.579-.6.579q-3.122,0-6.244,0c-.583,0-.583,0-.583.588,0,2.108-.009,4.217.008,6.325,0,.4-.129.519-.514.517-2.081-.012-4.162-.006-6.244-.005-.679,0-.679,0-.679.7,0,2.054-.01,4.108.008,6.161,0,.409-.113.536-.527.533q-3.224-.029-6.448,0c-.411,0-.535-.117-.532-.53q.028-3.223,0-6.447c0-.413.121-.533.532-.53,2.136.018,4.271,0,6.407.017.392,0,.51-.11.507-.5-.018-2.135,0-4.271-.017-6.406,0-.41.115-.537.529-.534,2.081.019,4.163.009,6.244.008.617,0,.618,0,.618-.637q0-3.1,0-6.2c0-.584.006-.588.593-.588Z"
              transform="translate(-5693.523 1782.41)"
              fill="currentColor"
            />
            <path
              id="Path_24520"
              data-name="Path 24520"
              d="M5391.025-2179.509h6.936c.54,0,.542,0,.542.547,0,2.135.009,4.271-.007,6.406,0,.379.115.485.488.483,2.135-.016,4.27-.008,6.405-.007.538,0,.541,0,.541.55,0,2.135-.008,4.27.008,6.405,0,.4-.1.547-.524.543q-3.264-.029-6.528,0c-.407,0-.507-.15-.506-.525.013-2.122.007-4.243.005-6.365,0-.544,0-.546-.539-.546H5384.18c-.641,0-.642,0-.643-.66q0-3.142,0-6.283c0-.536.014-.547.55-.547Z"
              transform="translate(-5361.155 2224.311)"
              fill="currentColor"
            />
            <path
              id="Path_24521"
              data-name="Path 24521"
              d="M6092.536-1821.693c0-1.1.008-2.2,0-3.3,0-.327.1-.458.44-.456q3.344.016,6.689,0c.293,0,.419.1.419.4q-.007,3.385,0,6.77c0,.287-.135.377-.4.376q-3.365-.006-6.729,0c-.323,0-.421-.137-.417-.445C6092.543-1819.464,6092.536-1820.579,6092.536-1821.693Z"
              transform="translate(-6025.357 1892.623)"
              fill="currentColor"
            />
          </g>
        </g>
      </svg>
    </Icon>
  );
};
