import { Button, Text } from '@chakra-ui/react';
import i18n from 'i18next';

const Option = props => {
  // const { isActive } = props;
  return (
    <>
      <Text
        transition="opacity .4s"
        opacity={props.isActive ? 1 : 0}
        letterSpacing="1.5px"
      >{`</`}</Text>
      <Text px="2px">{props.children}</Text>
      <Text
        transition="opacity .4s"
        opacity={props.isActive ? 1 : 0}
        letterSpacing="1.5px"
      >{`>`}</Text>
    </>
  );
};

const ButtonLanguage = props => {
  const { handleClick } = props;
  const isActive = i18n.language === props.language;
  return (
    <Button
      bg="unset"
      px="0"
      w="50px"
      minW="unset"
      color={isActive ? 'green.500' : 'rgba(255,255,255,.7)'}
      _hover={{ color: 'rgba(255,255,255,.7)' }}
      _active={{ outline: 'none' }}
      _focus={{ outline: 'none' }}
      onClick={() => handleClick(props.language)}
    >
      <Option isActive={isActive}>{props.children}</Option>
    </Button>
  );
};

export default ButtonLanguage;
