import { Flex, Heading, VStack, Text } from '@chakra-ui/react';
import SpheresLoading from './SpheresLoading';
import { useTranslation } from 'react-i18next';

const RoadmapCard = props => {
  const { content } = props;
  const isDone = content.isDone;
  const isProcess = content.isProcess;
  const titles = content.title;
  const contents = content.content;
  const { t } = useTranslation();

  return (
    <Flex
      w="100%"
      pt={{ base: '60px', lg: '80px' }}
      position="relative"
      bg="black"
      border={isDone ? '0px' : '1px'}
      rounded="lg"
      bgGradient={
        !isDone
          ? 'unset'
          : 'linear(to-r, rgba(0, 255, 177, 0.8) 1.76%, rgba(0, 255, 177, 0.35))'
      }
      borderColor="green.500"
      align="flex-end"
    >
      <Heading
        position="absolute"
        top="20px"
        fontSize={{ base: '32px', lg: '40px' }}
        left={!isProcess ? '20px' : 'unset'}
        right={!isProcess ? 'unset' : '20px'}
        color={isDone ? 'black' : 'white'}
      >
        {content.quarter}
      </Heading>
      <Text
        position="absolute"
        right="0px"
        top={!isProcess ? '32px' : 'unset'}
        bottom={!isProcess ? 'unset' : '32px'}
        fontSize="24px"
        transform="rotate(90deg)"
        color={isDone ? 'black' : 'green.500'}
      >
        {content.year}
      </Text>
      {isProcess && (
        <SpheresLoading position="absolute" top="-10px" left="20px" />
      )}

      <VStack align="flex-start" p="20px" pr="45px">
        {titles.map(title => (
          <Heading
            key={title}
            fontWeight="400"
            fontSize={{ base: '20px', lg: '28px' }}
            color={isDone ? 'black' : 'green.500'}
          >
            {t(title)}
          </Heading>
        ))}

        {contents.map(content => (
          <Text
            key={content}
            fontWeight="400"
            fontSize={{ base: '12px', lg: '16px' }}
            color={isDone ? 'black' : 'green.500'}
          >
            {t(content)}
          </Text>
        ))}
      </VStack>
    </Flex>
  );
};

export default RoadmapCard;
