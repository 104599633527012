import ReactGA from 'react-ga4';
import {
  chakra,
  Flex,
  Heading,
  Text,
  VStack,
  Link,
  HStack,
} from '@chakra-ui/react';
import { Link as ReachLink } from 'react-scroll';

import useSpace from '../../hooks/useSpace';
import serviceBg from '../../assets/images/service_bg.png';
import { useTranslation } from 'react-i18next';

const services = [
  {
    title: 'service_01',
    content: 'service_01_content',
    link: 'https://zinstitute.kaik.io/',
  },
  {
    title: 'service_02',
    content: 'service_02_content',
    to: 'Contact',
  },
  {
    title: 'service_03',
    content: 'service_03_content',
    to: 'Contact',
    works: [
      {
        title: 'Stader Labs',
        link: 'https://kkokebpfu7cug5efmqdhccssghk45lwpiojtfou2a3okbiez.arweave.net/U_pyiBeWnxUN0hWQGcQpSMdXOrs9DkzK6mgbcoKCZ_w',
      },
      {
        title: 'Edge Protocol',
        link: 'https://ayfjbaslpivk5zwgdfojbvyj47mxdhnkyxcfcongubgyppzd.arweave.net/BgqQgkt6Kq7mxhlck-NcJ59lxnarFxFE5_pqBNh78j4',
      },
      {
        title: 'QuickSwap',
        link: 'https://arweave.net/WdVz3HOLhVyx04RPZmHywLWZY-wvaVoqGUaSuh4QBdE',
      },
      {
        title: 'Tron BTTStaking',
        link: 'https://arweave.net/9ltyUdeWj8kvzFydMvv8Xyk46VdPY7aaj96dtShNC5A',
      },
    ],
  },
  {
    title: 'service_04',
    content: 'service_04_content',
    to: 'Contact',
  },
  {
    title: 'service_05',
    content: 'service_05_content',
    to: 'Contact',
    works: [
      {
        title: 'Opyn Polygon Integration',
        link: 'https://github.com/tina1998612/GammaProtocol/blob/polygon/POLYGON_DEPLOY.md',
      },
    ],
  },
];

const gaButtonHandler = link => {
  ReactGA.event({
    category: 'serviceLink',
    action: `service_link_click_${link}`,
  });
};

const ServiceItem = props => {
  const { t } = useTranslation();
  const { service } = props;
  return (
    <Flex
      role="group"
      rounded="xl"
      borderWidth="1px"
      borderColor="green.500"
      px={{ base: '24px', lg: '50px' }}
      pt={{ base: '15px', lg: '40px' }}
      pb="15px"
      pr="32px"
      w="100%"
      bg="black"
      _hover={{ boxShadow: '0 0 30px 0 #00ffb0' }}
      direction="column"
    >
      <Text
        fontSize={{ base: '20px', lg: '30px' }}
        color="green.500"
        lineHeight={{ base: '25px', lg: '30px' }}
      >
        <chakra.span color="white">{`{`}</chakra.span>
        {t(service.title)}
        <chakra.span color="white">{`}`}</chakra.span>
      </Text>
      <Text
        w="80%"
        pl="5px"
        align="left"
        fontSize="15px"
        lineHeight="30px"
        opacity=".8"
        pt="15px"
        display={{ base: 'none', lg: 'block' }}
      >
        {t(service.content)}
      </Text>
      <Flex wrap="wrap" spacing="16px" pt="15px">
        {service.works?.map(work => (
          <Text
            mx="5px"
            as={Link}
            href={work.link}
            isExternal
            key={work.title}
            align="left"
            fontSize="12px"
            lineHeight="30px"
          >
            <chakra.span color="green.500">[</chakra.span>
            {work.title}
            <chakra.span color="green.500">]</chakra.span>
          </Text>
        ))}
      </Flex>
      <Flex justify={{ base: 'flex-start', lg: 'flex-end' }}>
        <Link
          as={service.to && ReachLink}
          smooth={service.to && true}
          align="right"
          href={service.link}
          to={service.to}
          isExternal
          color="whiteO.400"
          fontSize="15px"
          transition="color .5s"
          pt={{ base: '10px', lg: '0' }}
          onClick={() => gaButtonHandler(t(service.title))}
          _groupHover={{ color: 'green.500' }}
        >
          GET STARTED →
        </Link>
      </Flex>
    </Flex>
  );
};

const Service = () => {
  const { space } = useSpace();
  const { t } = useTranslation();

  return (
    <Flex
      w="100%"
      justify="center"
      bg="black"
      bgImage={`url('${serviceBg}')`}
      bgSize="cover"
      borderTop="1px"
      borderBottom="1px"
      borderColor="green.500"
      py={{ base: '40px', lg: '50px' }}
    >
      <Flex w={space} direction="column" px="15px">
        <Heading align="left" fontSize={{ base: '60px', lg: '72px' }}>
          SERVICE
        </Heading>
        <VStack spacing={{ base: '15px', lg: '35px' }} py="20px">
          {services.map((service, index) => (
            <ServiceItem key={service.title + index} service={service} />
          ))}
        </VStack>
      </Flex>
    </Flex>
  );
};
export default Service;
