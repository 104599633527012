import { useRef, useLayoutEffect, useState, useEffect } from 'react';
import { Flex, Heading, Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import useSpace from '../../hooks/useSpace';
import AchievementCard from '../AchievementCard';
import AchievementZ from '../AchievementZ';
import achievementMarquee from '../../assets/images/achievement_marquee.svg';

import logoZBgBlack from '../../assets/images/logo/logo_z_bg_black.png';
import achievement02 from '../../assets/images/achievements/achievement02.jpg';
import achievement03 from '../../assets/images/achievements/achievement03.jpg';
import achievement04 from '../../assets/images/achievements/achievement04.jpg';
import achievement05 from '../../assets/images/achievements/achievement05.jpg';

const MotionBox = motion(Box);

const achievements = [
  {
    id: '2021-05',
    time: 'MAY 2021',
    content: ['achieved_01'],
    events: [
      {
        title: 'achieved_01',
        image: logoZBgBlack,
      },
    ],
  },
  {
    id: '2021-10',
    time: 'OCT 2021',
    content: ['achieved_02'],
    events: [
      {
        title: 'achieved_02',
        image: achievement02,
      },
    ],
  },
  {
    id: '2021-12',
    time: 'DEC 2021',
    content: ['achieved_03'],
    events: [
      {
        title: 'achieved_03',
        image: achievement03,
      },
      {
        title: 'achieved_03_02',
        link: 'https://kkokebpfu7cug5efmqdhccssghk45lwpiojtfou2a3okbiez.arweave.net/U_pyiBeWnxUN0hWQGcQpSMdXOrs9DkzK6mgbcoKCZ_w',
      },
    ],
  },
  {
    id: '2022-01',
    time: 'JUN 2022',
    content: ['achieved_04_01', 'achieved_04_02'],
    events: [
      {
        title: 'achieved_04_01',
        image: achievement04,
      },
      {
        title: 'achieved_04_02',
        link: 'https://github.com/tina1998612/GammaProtocol/blob/polygon/POLYGON_DEPLOY.md',
      },
    ],
  },
  {
    id: '2022-02',
    time: 'FEB 2022',
    content: ['achieved_05_01', 'achieved_05_02'],
    events: [
      {
        title: 'achieved_05_01',
        image: achievement05,
      },
      {
        title: 'achieved_05_02',
        link: 'https://ayfjbaslpivk5zwgdfojbvyj47mxdhnkyxcfcongubgyppzd.arweave.net/BgqQgkt6Kq7mxhlck-NcJ59lxnarFxFE5_pqBNh78j4',
      },
    ],
  },
];

const Achievement = () => {
  const ref = useRef(null);

  const [elementBoundary, setElementBoundary] = useState({});
  const [scrollY, setScrollY] = useState(0);
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const { space } = useSpace();

  const logit = () => {
    setScrollY(window.pageYOffset);
  };

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', logit);
    }
    watchScroll();
    return () => {
      window.removeEventListener('scroll', logit);
    };
  });

  useLayoutEffect(() => {
    const element = ref.current;
    setElementBoundary({
      top: element.offsetTop,
      height: element.offsetHeight,
    });
  }, [ref]);

  useEffect(() => {
    setScrollPercentage(
      (scrollY - elementBoundary.top + 260) / (elementBoundary.height - 50)
    );
  }, [scrollY, elementBoundary.top, elementBoundary.height]);

  return (
    <Flex
      w="100%"
      justify="center"
      ref={ref}
      py="80px"
      px="18px"
      borderTop="1px"
      borderBottom="1px"
      borderColor="green.500"
      position="relative"
      display={{ base: 'none', lg: 'flex' }}
    >
      <Flex
        w="80px"
        borderRight="1px"
        borderColor="green.500"
        h="100%"
        overflow="hidden"
        position="absolute"
        top="0"
        left="0"
        justify="center"
        display={{ base: 'none', md: 'block' }}
      >
        <MotionBox
          w="100%"
          h="100%"
          bgImage={`url('${achievementMarquee}')`}
          bgRepeat="repeat-y"
          bgPosition="center"
          initial={{ backgroundPosition: 'center 100%' }}
          animate={{ backgroundPosition: 'center 1000%' }}
          transition={{
            repeat: Infinity,
            ease: 'linear',
            duration: 20,
          }}
          src={achievementMarquee}
        />
      </Flex>
      <Flex
        w="80px"
        borderLeft="1px"
        borderColor="green.500"
        h="100%"
        overflow="hidden"
        position="absolute"
        top="0"
        right="0"
        justify="center"
        display={{ base: 'none', md: 'block' }}
      >
        <MotionBox
          w="100%"
          h="100%"
          bgImage={`url('${achievementMarquee}')`}
          bgRepeat="repeat-y"
          bgPosition="center"
          transform="scaleY(-1)"
          initial={{ backgroundPosition: 'center 100%' }}
          animate={{ backgroundPosition: 'center 1000%' }}
          transition={{
            repeat: Infinity,
            ease: 'linear',
            duration: 20,
          }}
          src={achievementMarquee}
        />
      </Flex>
      <Flex w={space} direction="column">
        <Heading
          fontSize={{ base: '60px', lg: '90px' }}
          fontWeight="400"
          py="32px"
        >
          What we
          <br /> have achieved
        </Heading>

        <Flex
          w="100%"
          align="flex-start"
          position="relative"
          direction={{ base: 'column', lg: 'row' }}
        >
          <Flex direction="column" w={{ base: '100%', lg: '60%' }}>
            {achievements.map(achievement => (
              <AchievementCard {...achievement} key={achievement.id} />
            ))}
          </Flex>
          <Flex
            position="sticky"
            top="400px"
            flex="1"
            justify={{ base: 'center', lg: 'flex-end' }}
            w="100%"
          >
            <Box top="50px">
              <AchievementZ process={scrollPercentage} />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Achievement;
