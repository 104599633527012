import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const AchievementZ = props => {
  const { process } = props;
  const [strokeColor, setColor] = useState('white');

  useEffect(() => {
    if (process > 0.7) {
      setColor('#00FFB0');
    } else {
      setColor('white');
    }
  }, [process]);

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="261"
        height="261"
        viewBox="0 0 261 261"
      >
        <g
          id="Group_515"
          data-name="Group 515"
          transform="translate(-1027.5 -494.5)"
        >
          {/* 上方線段 */}
          <motion.line
            initial={{ pathLength: 0 }}
            animate={{ pathLength: process > 0.15 ? 1 : 0 }}
            // opacity=".5"
            data-name="Line 16"
            x2="200"
            transform="translate(1058 510)"
            fill="none"
            stroke={strokeColor}
            strokeWidth="2"
          />
          {/* 下方線段 */}

          <motion.line
            initial={{ pathLength: 0 }}
            animate={{ pathLength: process > 0.4 ? 1 : 0 }}
            id="Line_17"
            data-name="Line 17"
            x2="200"
            transform="translate(1058 740)"
            fill="none"
            stroke={strokeColor}
            strokeWidth="2"
          />
          {/* 斜線段 */}
          <motion.line
            animate={{ pathLength: process > 0.3 ? 1 : 0 }}
            id="Line_18"
            data-name="Line 18"
            x1="231"
            y2="230"
            transform="translate(1042.5 510.5)"
            fill="none"
            stroke={strokeColor}
            strokeWidth="2"
          />
          {/* 左上外圍 */}
          <rect
            width="30"
            height="30"
            transform="translate(1028 495)"
            stroke={strokeColor}
            strokeWidth="1"
          />
          {/* 中間外圍 */}
          <rect
            id="Rectangle_45"
            data-name="Rectangle 45"
            width="30"
            height="30"
            transform="translate(1143 610)"
            stroke={strokeColor}
            strokeWidth="1"
          />
          {/*左下外圍*/}
          <rect
            id="Rectangle_44"
            data-name="Rectangle 44"
            width="30"
            height="30"
            transform="translate(1028 725)"
            stroke={strokeColor}
            strokeWidth="1"
          />
          {/*右上外圍*/}
          <rect
            id="Rectangle_42"
            data-name="Rectangle 42"
            width="30"
            height="30"
            transform="translate(1258 495)"
            stroke={strokeColor}
            strokeWidth="1"
          />
          {/*右下外圍*/}
          <rect
            id="Rectangle_43"
            data-name="Rectangle 43"
            width="30"
            height="30"
            transform="translate(1258 725)"
            stroke={strokeColor}
            strokeWidth="1"
          />
          {/* 右上內框 */}
          <motion.rect
            animate={{ opacity: process > 0.1 ? 1 : 0 }}
            id="Rectangle_46"
            data-name="Rectangle 46"
            width="14"
            height="14"
            transform="translate(1036 503)"
            fill={strokeColor}
          />
          {/* 右上內框 */}
          <motion.rect
            animate={{ opacity: process > 0.2 ? 1 : 0 }}
            id="Rectangle_47"
            data-name="Rectangle 47"
            width="14"
            height="14"
            transform="translate(1266 503)"
            fill={strokeColor}
          />
          {/* 中間內框 */}
          <motion.rect
            animate={{ opacity: process > 0.3 ? 1 : 0 }}
            id="Rectangle_48"
            data-name="Rectangle 48"
            width="14"
            height="14"
            transform="translate(1151 618)"
            fill={strokeColor}
          />
          {/* 左下內框 */}
          <motion.path
            animate={{ opacity: process > 0.4 ? 1 : 0 }}
            id="Path_27219"
            data-name="Path 27219"
            d="M0,0H14V14H0Z"
            transform="translate(1036 733)"
            fill={strokeColor}
          />
          {/* 右下內框 */}
          <motion.rect
            animate={{ opacity: process > 0.5 ? 1 : 0 }}
            id="Rectangle_50"
            data-name="Rectangle 50"
            width="14"
            height="14"
            transform="translate(1266 733)"
            fill={strokeColor}
          />
        </g>
      </svg>
    </>
  );
};

export default AchievementZ;
