import { useState } from 'react';
import { Link } from '@chakra-ui/react';

import { Link as ReachLink } from 'react-scroll';

const NavLink = props => {
  const { linkURL } = props;
  const isLink = !!linkURL;

  const [isActive, setIsActive] = useState(false);
  return (
    <>
      {!isLink && (
        <Link
          offset={-50}
          spy={true}
          hashSpy={true}
          key={props.title}
          as={ReachLink}
          to={props.anchor}
          onClick={props.onClick}
          position="relative"
          smooth={true}
          py={{ base: '10px', lg: '20px' }}
          color={isActive ? 'green.500' : 'whiteO.700'}
          onSetActive={() => setIsActive(true)}
          onSetInactive={() => setIsActive(false)}
          fontSize={{ base: '18px', md: '16px' }}
        >
          {props.title}
        </Link>
      )}
      {isLink && (
        <Link
          isExternal
          color="whiteO.700"
          py={{ base: '10px', lg: '20px' }}
          href={linkURL}
          fontSize={{ base: '18px', md: '16px' }}
        >
          {props.title}
        </Link>
      )}
    </>
  );
};

export default NavLink;
