import ReactGA from 'react-ga4';
import {
  Flex,
  Grid,
  GridItem,
  Image,
  Box,
  AspectRatio,
  Text,
  Link,
  HStack,
  Icon,
  useDisclosure,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { BsInstagram, BsYoutube, BsFacebook, BsTwitter } from 'react-icons/bs';
import { FaDiscord } from 'react-icons/fa';

import NftModal from '../../components/NftModal';

import education from '../../assets/images/hero/education.png';
import circleWeb2ToWeb3 from '../../assets/images/hero/circle_web2ToWeb3.svg';
import corner from '../../assets/images/hero/corner.svg';
import AibKGg from '../../assets/images/AibKGg.png';
import ethereum from '../../assets/images/hero/ethereum.svg';
import development from '../../assets/images/hero/development.png';
import nft from '../../assets/images/hero/nft.gif';
import arrowGroup from '../../assets/images/hero/arrowGroup.svg';
import service from '../../assets/images/hero/service.mp4';
import zinstitute from '../../assets/images/hero/zinstitute.gif';
import arrow from '../../assets/images/hero/arrow.gif';
import waferZ from '../../assets/images/hero/waferZ.mp4';
import waferZMobile from '../../assets/images/hero/waferZ.jpg';
import edu from '../../assets/images/hero/education.webm';

const MotionImage = motion(Image);
const MotionText = motion(Text);
const MotionFlex = motion(Flex);

const videoStyle = {
  position: 'absolute',
  zIndex: '5',
  objectFit: 'cover',
};

const Web2ToWeb3 = () => {
  return (
    <AspectRatio w="100%" ratio={{ base: 1, lg: '4/1' }} position="relative">
      <Box position="absolute" w="100%" h="100%">
        <Box
          position="absolute"
          w="80%"
          top="50%"
          left="50%"
          transform="translate(-50%,-50%)"
        >
          <MotionImage
            src={circleWeb2ToWeb3}
            w="100%"
            animate={{ rotate: 360 }}
            transition={{
              duration: 10,
              ease: 'linear',
              repeat: Infinity,
            }}
          />
        </Box>
        <Image
          src={education}
          position="absolute"
          w="40%"
          top="50%"
          left="50%"
          transform="translate(-50%,-50%)"
        />
        <Image
          src={corner}
          position="absolute"
          w="90%"
          top="50%"
          left="50%"
          transform="translate(-50%,-50%)"
        />
      </Box>
    </AspectRatio>
  );
};

const HeroMobile = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex
        w="100%"
        justify="center"
        display={{ base: 'flex', lg: 'none' }}
        pb="20px"
        pt="30px"
        bgImage="linear-gradient(rgba(0,255,176,0.2) 1px, transparent 1px), linear-gradient(to right, rgba(0,255,176,0.2) 1px, black 1px)"
        bgSize="20px 20px"
      >
        <Flex w="100%">
          <Grid
            w="100%"
            px="24px"
            h={{ base: 'unset', lg: '90vh' }}
            mt="40px"
            templateRows={{ base: 'unset', lg: 'repeat(10, 1fr)' }}
            templateColumns={{ base: 'repeat(1,1fr)', lg: 'repeat(15,1fr)' }}
            gap={4}
          >
            {/* hero image */}
            <GridItem
              w="100%"
              h="200px"
              colSpan={{ base: 1, lg: 'unset' }}
              area={{ base: 'unset', lg: '1/4/7/12' }}
              position="relative"
            >
              <Image
                h="100%"
                w="100%"
                border="1px"
                borderColor="green.500"
                rounded="xl"
                src={waferZMobile}
                objectFit="cover"
              />
            </GridItem>
            {/* web2 to web3 */}
            {/* 先拿掉 */}
            {/* <GridItem
              w="100%"
              h="100%"
              colSpan={{ base: 1, lg: 'unset' }}
              area={{ base: 'unset', lg: '1/1/4/4' }}
              rounded="base"
              border="1px"
              borderColor="green.500"
              position="relative"
              bg="black"
            >
              <Web2ToWeb3 />
            </GridItem> */}
            {/* hack the future */}
            <GridItem
              rounded="lg"
              w="100%"
              borderWidth="1px"
              borderColor="green.500"
              h="38px"
              display="flex"
              bg="black"
            >
              <Flex
                align="center"
                px="12px"
                borderRight="1px"
                borderColor="green.500"
              >
                <Box>
                  <MotionImage
                    src={AibKGg}
                    w="20px"
                    zIndex="-1"
                    animate={{ rotateY: 180 }}
                    transition={{ duration: 2, repeat: Infinity, delay: 1 }}
                    style={{ transformStyle: 'preserve-3d' }}
                  />
                </Box>
              </Flex>
              <Flex
                w="100%"
                overflow="hidden"
                align="center"
                position="relative"
              >
                <MotionText
                  position="absolute"
                  px="12px"
                  fontSize={{ base: '16px', md: '12px', lg: '16px' }}
                  w="400px"
                  noOfLines={1}
                  initial={{ x: 0 }}
                  animate={{ x: -400 }}
                  transition={{
                    repeat: Infinity,
                    ease: 'linear',
                    duration: 25,
                  }}
                >
                  Hack the future. Enlighten the future
                </MotionText>
              </Flex>
            </GridItem>
            {/* <GridItem
              borderWidth="1px"
              borderColor="green.500"
              display="flex"
              rounded="lg"
              bg="black"
            >
              <Flex flex="1" direction="column">
                <Flex
                  h="400px"
                  position="relative"
                  justify="center"
                  align="center"
                >
                  <Box
                    w="120px"
                    h="260px"
                    as="video"
                    src={service}
                    loop
                    muted
                    autoPlay
                    preload="auto"
                    type="video"
                    objectFit="cover"
                  ></Box>
                </Flex>
                <Flex
                  borderTop="1px"
                  borderColor="green.500"
                  w="100%"
                  justify="center"
                  align="center"
                >
                  <Image src={zinstitute} w="60%" />
                </Flex>
              </Flex>
              <Flex
                py="20px"
                px="12px"
                w="80px"
                borderLeft="1px"
                borderColor="green.500"
                direction="column"
              >
                <Flex w="100%" my="10px">
                  <Image src={ethereum} w="100%" />
                </Flex>
                <Image src={development} w="100%" />
              </Flex>
            </GridItem> */}
            <GridItem
              rounded="xl"
              border="1px"
              borderColor="green.500"
              display="flex"
              flexDirection="column"
              alignItems="center"
              bg="black"
            >
              <Flex
                w="100%"
                p="24px"
                borderBottom="1px"
                borderColor="green.500"
                align="center"
                justify="center"
              >
                <Image src={nft} w="80%" />
              </Flex>
              <Text
                cursor="pointer"
                py="16px"
                fontSize="30px"
                color="green.500"
                textTransform="uppercase"
                onClick={onOpen}
              >
                coming soon →
              </Text>
            </GridItem>
          </Grid>
        </Flex>
      </Flex>
      <NftModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

const HeroDesktop = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const gaButtonHandler = social => {
    ReactGA.event({
      category: 'HeroIcon',
      action: `HeroIconClick_${social}`,
    });
  };
  return (
    <>
      <Flex
        w="100%"
        px="24px"
        pt="114px"
        pb="24px"
        top="0"
        display={{ base: 'none', lg: 'flex' }}
        bgImage="linear-gradient(rgba(0,255,176,0.3) 1px, transparent 1px), linear-gradient(to right, rgba(0,255,176,0.3) 1px, black 1px)"
        bgSize="20px 20px"
        justify="center"
      >
        <Flex w="100%" maxW="1440px" minH="80vh">
          {/* left column */}
          <Flex w="320px" pr="20px" direction="column" align="center">
            <Flex
              w="100%"
              justify="center"
              borderWidth="1px"
              borderColor="green.500"
              rounded="xl"
              bg="black"
            >
              <Web2ToWeb3 />
            </Flex>
            <Flex
              mt="20px"
              rounded="xl"
              direction="column"
              border="1px"
              borderColor="green.500"
              flex="1"
              bg="black"
            >
              <Flex
                w="100%"
                p="24px"
                borderBottom="1px"
                borderColor="green.500"
                align="center"
                justify="center"
                flex="1"
              >
                <Image src={nft} w="100%" />
              </Flex>
              <MotionText
                cursor="pointer"
                onClick={onOpen}
                py="16px"
                fontSize="30px"
                color="green.500"
                textTransform="uppercase"
                textAlign="center"
                animate={{ x: 10 }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  delay: 1,
                  repeatType: 'reverse',
                }}
              >
                coming soon →
              </MotionText>
            </Flex>
          </Flex>
          <Flex flex="1" direction="column">
            <Flex flex="1">
              <Flex flex="1" direction="column">
                {/* center column top */}
                <Flex
                  mb="16px"
                  flex="1"
                  rounded="lg"
                  overflow="hidden"
                  position="relative"
                  w="100%"
                  h="100%"
                >
                  <Box
                    border="1px"
                    borderColor="green.500"
                    h="100%"
                    w="100%"
                    rounded="xl"
                    as="video"
                    src={waferZ}
                    loop
                    muted
                    autoPlay
                    preload="auto"
                    type="video"
                    style={videoStyle}
                  ></Box>
                  <Image
                    h="100%"
                    w="100%"
                    position="absolute"
                    src={waferZMobile}
                    objectFit="cover"
                  />
                </Flex>
                <Flex
                  borderWidth="1px"
                  borderColor="green.500"
                  rounded="xl"
                  h="58px"
                  bg="black"
                >
                  <Flex
                    align="center"
                    px="20px"
                    borderRight="1px"
                    borderColor="green.500"
                  >
                    <MotionImage
                      src={AibKGg}
                      w="20px"
                      animate={{ rotateY: 180 }}
                      transition={{ duration: 1.5, repeat: Infinity, delay: 1 }}
                      style={{ transformStyle: 'preserve-3d' }}
                    />
                  </Flex>
                  <Flex
                    flex="1"
                    align="center"
                    overflow="hidden"
                    position="relative"
                  >
                    <MotionFlex
                      spacing={5}
                      w="1800px"
                      position="absolute"
                      px="12px"
                      fontSize={{ base: '16px', md: '12px', lg: '16px' }}
                      // noOfLines={1}
                      initial={{ x: 0 }}
                      animate={{ x: -800 }}
                      transition={{
                        repeat: Infinity,
                        ease: 'linear',
                        duration: 15,
                      }}
                    >
                      <Text px="20px" fontSize={{ lg: '16px', xl: '20px' }}>
                        Hack the future. Enlighten the future
                      </Text>
                      <Text px="20px" fontSize={{ lg: '16px', xl: '20px' }}>
                        Hack the future. Enlighten the future
                      </Text>
                      <Text px="20px" fontSize={{ lg: '16px', xl: '20px' }}>
                        Hack the future. Enlighten the future
                      </Text>
                    </MotionFlex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                w="320px"
                direction="row"
                border="1px"
                borderColor="green.500"
                bg="black"
                ml="20px"
                rounded="xl"
              >
                <Flex flex="1" direction="column">
                  <Flex
                    h="300px"
                    position="relative"
                    justify="center"
                    align="center"
                  >
                    <Box
                      w="120px"
                      h="260px"
                      as="video"
                      src={service}
                      loop
                      muted
                      autoPlay
                      preload="auto"
                      type="video"
                      objectFit="cover"
                    ></Box>
                  </Flex>
                  <Flex
                    flex="1"
                    borderTop="1px"
                    borderColor="green.500"
                    w="100%"
                    justify="center"
                    align="center"
                  >
                    <Image src={zinstitute} w="70%" />
                  </Flex>
                </Flex>
                <Flex
                  py="20px"
                  px="12px"
                  w="80px"
                  borderLeft="1px"
                  borderColor="green.500"
                  direction="column"
                >
                  <Flex w="100%" my="10px">
                    <MotionImage
                      animate={{ rotateY: 360 }}
                      transition={{ duration: 3, repeat: Infinity, delay: 1 }}
                      src={ethereum}
                      w="100%"
                    />
                  </Flex>
                  <Image mt="20px" src={development} w="100%" />
                </Flex>
              </Flex>
            </Flex>
            {/* right bottom */}
            <Flex h="215px" pt="20px">
              <Flex
                flex="1"
                borderWidth="1px"
                borderColor="green.500"
                rounded="xl"
                bg="black"
              >
                <Flex
                  w="70%"
                  borderRight="1px"
                  borderColor="green.500"
                  p="20px"
                  direction="column"
                >
                  <MotionText
                    textTransform="uppercase"
                    color="green.500"
                    fontSize="72px"
                    lineHeight="90px"
                    fontWeight="500"
                    animate={{ rotateX: 360 }}
                    transition={{ duration: 2, repeat: Infinity, delay: 1 }}
                    style={{ transformStyle: 'preserve-3d' }}
                  >
                    Blockchain
                  </MotionText>
                  <Flex
                    color="green.500"
                    align="center"
                    fontSize="24px"
                    mt="10px"
                  >
                    <Flex position="relative" h="40px" flex="1">
                      <Box
                        h="100%"
                        w="450px"
                        rounded="xl"
                        as="video"
                        src={edu}
                        loop
                        muted
                        autoPlay
                        preload="auto"
                        type="video"
                        style={videoStyle}
                      ></Box>
                    </Flex>
                    <HStack mt="10px" mx="24px" spacing={4} align="center">
                      <Link
                        href="https://www.instagram.com/the_z_institute/"
                        isExternal
                        onClick={() => gaButtonHandler('instagram')}
                      >
                        <Icon as={BsInstagram} />
                      </Link>
                      <Link
                        onClick={() => gaButtonHandler('instagram')}
                        href="https://www.youtube.com/channel/UC42E-54LCmsclJJ2-LZ7RdA"
                        isExternal
                      >
                        <Icon as={BsYoutube} />
                      </Link>
                    </HStack>
                  </Flex>
                </Flex>
                <Flex flex="1" px="20px" align="center" justify="center">
                  <Image src={arrow} w="70%" />
                </Flex>
              </Flex>
              <Flex
                w="80px"
                bg="black"
                rounded="xl"
                borderWidth="1px"
                borderColor="green.500"
                ml="20px"
                direction="column"
                justify="space-between"
                py="20px"
                px="8px"
                align="center"
                color="green.500"
                fontSize="24px"
              >
                <Image src={arrowGroup} w="100%" />

                <Flex
                  w="100%"
                  borderLeft="1px"
                  borderRight="1px"
                  borderColor="green.500"
                  justify="center"
                >
                  <Link
                    href="https://www.facebook.com/the.z.institute"
                    onClick={() => gaButtonHandler('facebook')}
                    isExternal
                  >
                    <Icon as={BsFacebook} />
                  </Link>
                </Flex>

                <Flex
                  w="100%"
                  borderLeft="1px"
                  borderRight="1px"
                  borderColor="green.500"
                  justify="center"
                >
                  <Link
                    href="https://discord.gg/WtCFxxJSu4"
                    onClick={() => gaButtonHandler('discord')}
                    isExternal
                  >
                    <Icon as={FaDiscord} />
                  </Link>
                </Flex>
                <Flex
                  w="100%"
                  borderLeft="1px"
                  borderRight="1px"
                  borderColor="green.500"
                  justify="center"
                >
                  <Link
                    href="https://twitter.com/the_z_institute"
                    onClick={() => gaButtonHandler('twitter')}
                    isExternal
                  >
                    <Icon as={BsTwitter} />
                  </Link>
                </Flex>
                <Image src={arrowGroup} transform="scaleX(-1)" w="100%" />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <NftModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

const Hero = () => {
  return (
    <>
      <HeroMobile />
      <HeroDesktop />
    </>
  );
};

export default Hero;
