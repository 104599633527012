import ReactGA from 'react-ga4';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  HStack,
  VStack,
  Image,
  Flex,
  Link,
} from '@chakra-ui/react';

import logoZ from '../assets/images/logo/logo_z.svg';
import modalClose from '../assets/images/modal_close.svg';

const NftModal = props => {
  const { isOpen, onClose } = props;

  const gaButtonHandler = content => {
    ReactGA.event({
      category: 'NFTModalClick',
      action: `NFTModalClick_${content}`,
    });
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} w="auto" size="sm">
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(15deg)"
      />
      <ModalContent bg="black" border="1px" borderColor="green.500">
        <Flex
          w="100%"
          h="36px"
          align="center"
          borderBottom="1px"
          borderColor="green.500"
          justify="space-between"
          px="20px"
        >
          <HStack flex="1">
            <Image src={logoZ} h="12px" />
            <Text
              fontSize="15px"
              color="green.500"
              whiteSpace="noWrap"
              w={{ base: '200px', lg: 'unset' }}
              display={{ base: 'none', lg: 'block' }}
              overflow="hidden"
            >
              NFT
            </Text>
          </HStack>
          <Image src={modalClose} onClick={onClose} cursor="pointer" />
          <HStack></HStack>
        </Flex>
        {/* </ModalHeader> */}
        <ModalBody display="flex" alignItems="center" justifyContent="center">
          <VStack spacing="10px" py="20px">
            <Link
              onClick={() => gaButtonHandler('NFT_READ_MORE')}
              href="https://the-z-institute.gitbook.io/the-z-institute/the-z-institue/xue-yuan-yuan-jing"
              isExternal
            >
              <Text>閱讀完整白皮書 →</Text>
            </Link>
            <Link
              onClick={() => gaButtonHandler('discord_invite')}
              href="https://discord.gg/WtCFxxJSu4"
              isExternal
            >
              <Text>加入 Discord 取得白名單 →</Text>
            </Link>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NftModal;
