import { Flex, Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import hackTheFutureMarquee from '../assets/images/hackTheFutureMarquee.png';

const MotionBox = motion(Box);

const Marquee = props => {
  return (
    <MotionBox
      h="120px"
      borderBottom="1px"
      borderColor="green.500"
      bgRepeat="repeat-x"
      bgPosition="center"
      bgImage={`url('${hackTheFutureMarquee}')`}
      initial={{ backgroundPosition: '0% center' }}
      animate={{ backgroundPosition: '700% center' }}
      transition={{
        repeat: Infinity,
        ease: 'linear',
        duration: 45,
      }}
      {...props}
    ></MotionBox>
  );
};

const MarqueeMobile = props => {
  return (
    <MotionBox
      h="72px"
      borderBottom="1px"
      borderColor="green.500"
      bgRepeat="repeat-x"
      bgPosition="center"
      bgImage={`url('${hackTheFutureMarquee}')`}
      initial={{ backgroundPosition: '0% center' }}
      animate={{ backgroundPosition: '100% center' }}
      transition={{
        repeat: Infinity,
        ease: 'linear',
        duration: 45,
      }}
      {...props}
    ></MotionBox>
  );
};

const WorksHackTheFuture = () => {
  return (
    <>
      <Flex direction="column" mb="80px" display={{ base: 'flex', lg: 'none' }}>
        <MarqueeMobile />
        <MarqueeMobile
          initial={{ backgroundPosition: '150% center' }}
          animate={{ backgroundPosition: '-50% center' }}
        />
        <MarqueeMobile
          initial={{ backgroundPosition: '200% center' }}
          animate={{ backgroundPosition: '300% center' }}
        />
      </Flex>

      <Flex
        direction="column"
        mb="200px"
        display={{ base: 'none', lg: 'flex' }}
      >
        <Marquee />
        <Marquee
          initial={{ backgroundPosition: '150% center' }}
          animate={{ backgroundPosition: '-850% center' }}
        />
        <Marquee
          initial={{ backgroundPosition: '255% center' }}
          animate={{ backgroundPosition: '955% center' }}
        />
      </Flex>
    </>
  );
};

export default WorksHackTheFuture;
