import ReactGA from 'react-ga4';
import { Icon, Link, HStack } from '@chakra-ui/react';
import { BsInstagram, BsYoutube, BsFacebook, BsTwitter } from 'react-icons/bs';
import { FaDiscord } from 'react-icons/fa';

const socialList = [
  {
    name: 'discord',
    icon: FaDiscord,
    link: 'https://discord.gg/WtCFxxJSu4',
  },
  {
    name: 'facebook',
    icon: BsFacebook,
    link: 'https://www.facebook.com/the.z.institute',
  },
  {
    name: 'twitter',
    icon: BsTwitter,
    link: 'https://mobile.twitter.com/the_z_institute',
  },
  {
    name: 'youtube',
    icon: BsYoutube,
    link: 'https://www.youtube.com/channel/UC42E-54LCmsclJJ2-LZ7RdA',
  },
  {
    name: 'instagram',
    icon: BsInstagram,
    link: 'https://www.instagram.com/the_z_institute/',
  },
];

const SocialLink = props => {
  const gaButtonHandler = social => {
    ReactGA.event({
      category: 'SocialLink',
      action: `SocialLinkClick_${social}`,
    });
  };
  return (
    <HStack spacing="20px" color="green.500">
      {socialList.map(item => (
        <Link
          key={item.link}
          href={item.link}
          onClick={() => gaButtonHandler(item.name)}
          isExternal
        >
          <Icon as={item.icon} fontSize={props.fontSize || '30px'} />
        </Link>
      ))}
    </HStack>
  );
};

export default SocialLink;
