import {
  Flex,
  Box,
  keyframes,
  usePrefersReducedMotion,
} from '@chakra-ui/react';

const load = keyframes`
  0% {  
    opacity: 0;
    transform: rotateY(-90deg) rotateX(-10deg) translateZ(35px) rotateY(90deg) scale3d(1, 1, 1); 
  }
  25%  {
    opacity: 1;
    transform: rotateY(0deg) rotateX(-15deg) translateZ(45px) rotateY(0deg)scale3d(1.1, 1.1, 1.1); 
  }
  50%  { 
    opacity: 0.2;
    transform: rotateY(90deg) rotateX(-10deg) translateZ(45px) rotateY(-90deg) scale3d(1, 1, 1); 
  }
   75% {
        opacity: 0;
        transform: rotateY(180deg) rotateX(0deg) translateZ(45px)
            rotateY(-180deg) scale3d(0.9, 0.9, 0.9);
    }
    100% {
        opacity: 0;
        transform: rotateY(270deg) rotateX(0deg) translateZ(35px)
            rotateY(-270deg) scale3d(1, 1, 1);
    }
`;

const Ball = props => {
  const { delay } = props;
  return (
    <Box
      opacity="0"
      h="25px"
      w="25px"
      borderRadius="50%"
      bg="green.500"
      boxShadow="inset -5px -5px 10px rgba(0, 0, 0, 0.3)"
      position="absolute"
      style={{ animationDelay: `${delay}s` }}
      {...props}
    ></Box>
  );
};

const SpheresLoading = props => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const animation = prefersReducedMotion
    ? undefined
    : `${load} infinite 2.5s linear`;

  return (
    <Flex
      position="relative"
      align="center"
      justify="center"
      w="100px"
      h="100px"
      {...props}
    >
      <Ball animation={animation} delay=".5" />
      <Ball animation={animation} delay="1" />
      <Ball animation={animation} delay="1.5" />
      <Ball animation={animation} delay="2" />
    </Flex>
  );
};
export default SpheresLoading;
