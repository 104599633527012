import ReactGA from 'react-ga4';
import { Flex, useDisclosure } from '@chakra-ui/react';

import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import NavLink from './NavLink';

const MenuDrawer = props => {
  const { menu } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const gaButtonHandler = link => {
    ReactGA.event({
      category: 'menu',
      action: `mobileMenuClick_${link}`,
    });
  };

  return (
    <Flex>
      {!isOpen && (
        <HamburgerIcon color="green.500" onClick={onOpen} w={6} h={6} />
      )}
      {isOpen && <CloseIcon color="green.500" onClick={onClose} w={4} h={4} />}

      {isOpen && (
        <Flex
          position="fixed"
          bg="rgba(0,0,0,0.8)"
          top="48px"
          left="0px"
          w="100vw"
          style={{
            backdropFilter: ' blur(14px)',
            WebkitBackdropFilter: 'blur(14px)',
          }}
          h="calc(100vh - 96px)"
          direction="column"
          align="center"
          justify="center"
        >
          {menu.map(item => (
            <NavLink
              onClick={() => {
                onClose();
                gaButtonHandler(item.title);
              }}
              key={item.title}
              {...item}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default MenuDrawer;
