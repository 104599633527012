import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Flex, Image, HStack, Link } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import i18n from 'i18next';
import { Link as ReachLink } from 'react-scroll';

import ButtonLanguage from '../ButtonLanguage';
import logo from '../../assets/images/logo/logo.svg';

import MenuDrawer from '../MenuDrawer';

import NavLink from '../NavLink';

const MotionFlex = motion(Flex);

const menu = [
  {
    title: 'ABOUT',
    anchor: 'about',
  },
  {
    title: 'VISION',
    anchor: 'vision',
  },
  {
    title: 'SERVICE',
    anchor: 'service',
  },
  {
    title: 'COURSE',
    anchor: 'course',
  },
  {
    title: 'INSTRUCTOR',
    anchor: 'instructor',
  },
  {
    title: 'PARTNER',
    anchor: 'partner',
  },
  {
    title: 'Q&A',
    anchor: 'Q&A',
  },
  {
    title: 'blog',
    linkURL: 'https://medium.com/the-z-institute',
  },
];

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isShow, setIsShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const currentLanguage = i18n.language;
  const hash = location.hash;

  const gaButtonHandler = link => {
    ReactGA.event({
      category: 'menu',
      action: `desktopMenuClick_${link}`,
    });
  };

  const variants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: -80 },
  };

  useEffect(() => {
    //only inside the hook
    const controlNavbar = () => {
      if (typeof window !== 'undefined') {
        if (window.scrollY > lastScrollY + 0.5 && window.scrollY > 80) {
          // if scroll down & over 80 hide the navbar
          setIsShow(false);
        } else {
          // if scroll up show the navbar
          setIsShow(true);
        }

        // remember current page location to use in the next move
        setLastScrollY(window.scrollY);
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  const handleChangeLanguage = lng => {
    ReactGA.event({
      category: 'Language switch',
      action: `languageSwitch${lng}`,
    });
    if (hash) {
      i18n.changeLanguage(lng);
      navigate(`${lng}${hash}`, { replace: true });
    } else {
      navigate(`${lng}`, { replace: true });
      i18n.changeLanguage(lng);
      localStorage.setItem('language', lng);
    }
  };

  return (
    <Flex
      w="100%"
      px={{ base: '0', lg: '24px' }}
      position="sticky"
      top={{ base: '0', lg: '20px' }}
      zIndex="10"
      justify="center"
      h="0"
    >
      <MotionFlex
        transition={{ duration: 0.5 }}
        animate={isShow ? 'open' : 'closed'}
        variants={variants}
        w="100%"
        maxW="1440px"
        overflow="hidden"
        rounded={{ base: 'unset', lg: 'xl' }}
        borderWidth="1px"
        borderTop={{ base: '0px', lg: '1px' }}
        borderLeft={{ base: '0px', lg: '1px' }}
        borderRight={{ base: '0px', lg: '1px' }}
        borderColor={{ base: 'green.500', lg: 'green.500' }}
        h={{ base: '48px', lg: '78px' }}
      >
        {/* logo */}
        <Flex
          flex={{ base: '1', lg: 'unset' }}
          borderRight="1px"
          borderColor="green.500"
          align="center"
          px="20px"
          justify="center"
          bg={{ base: 'rgba(0,0,0,0.8)', lg: 'rgba(0,0,0,0.6)' }}
          style={{
            backdropFilter: ' blur(14px)',
            WebkitBackdropFilter: 'blur(14px)',
          }}
        >
          <Link as={ReachLink} to="hero" smooth={true}>
            <Image src={logo} h={{ base: '32px', lg: '50px' }} />
          </Link>
        </Flex>
        {/* menu */}
        <Flex
          flex="1"
          px="50px"
          align="center"
          justify="space-around"
          display={{ base: 'none', lg: 'flex' }}
          bg={{ base: 'rgba(0,0,0,0.8)', lg: 'rgba(0,0,0,0.6)' }}
          style={{
            backdropFilter: ' blur(14px)',
            WebkitBackdropFilter: 'blur(14px)',
          }}
        >
          {menu.map(item => (
            <NavLink
              onClick={() => gaButtonHandler(item.title)}
              key={item.title}
              {...item}
            />
          ))}
          <Link
            w="0"
            offset={-50}
            spy={true}
            hashSpy={true}
            as={ReachLink}
            to="roadmap"
            position="relative"
            smooth={true}
            py={{ base: '10px', lg: '20px' }}
            fontSize={{ base: '18px', md: '16px' }}
          ></Link>
        </Flex>
        {/* language option */}
        <HStack
          borderLeft="1px"
          borderColor="green.500"
          px="20px"
          bg={{ base: 'rgba(0,0,0,0.8)', lg: 'rgba(0,0,0,0.6)' }}
          style={{
            backdropFilter: ' blur(14px)',
            WebkitBackdropFilter: 'blur(14px)',
          }}
        >
          <ButtonLanguage
            language="zh"
            currentLanguage={currentLanguage}
            handleClick={handleChangeLanguage}
          >
            中
          </ButtonLanguage>
          <ButtonLanguage
            language="en"
            currentLanguage={currentLanguage}
            handleClick={handleChangeLanguage}
          >
            EN
          </ButtonLanguage>
        </HStack>
        {/* hamburger */}
        <Flex
          display={{ base: 'flex', lg: 'none' }}
          w="50px"
          bg="rgba(0,0,0,0.9)"
          align="center"
          justify="center"
          borderLeft="1px"
          borderColor="green.500"
          color="green.500"
        >
          {/* <HamburgerIcon /> */}
          <MenuDrawer menu={menu} />
        </Flex>
      </MotionFlex>
    </Flex>
  );
};

export default Header;
