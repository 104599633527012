import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Flex, Text, Link, Icon } from '@chakra-ui/react';

const CourseCalendarCard = props => {
  const { name, content, image, isOdd, link, contentZh } = props;
  const { i18n } = useTranslation();
  const isEn = i18n.language === 'en';

  const gaButtonHandler = courseName => {
    ReactGA.event({
      category: 'course',
      action: `course_${courseName}_click`,
    });
  };

  return (
    <Flex
      onClick={() => gaButtonHandler(name)}
      role="group"
      flexShrink="0"
      mx="10px"
      my="10px"
      w={props.w || 'auto'}
      h={{ base: '270px', lg: '420px' }}
      bg={isOdd ? 'green.500' : 'black'}
      color={isOdd ? 'black' : 'green.500'}
      borderWidth="1px"
      borderColor="green.500"
      direction="column"
      rounded="xl"
      px="20px"
      pt={{ base: '20px', lg: '50px' }}
      pb="20px"
      _hover={{
        bg: isOdd ? 'black' : 'green.500',
        borderWidth: '3px',
        color: isOdd ? 'green.500' : 'black',
        transform: 'translate(0px, -8px)',
      }}
      transition="all 0.3s"
    >
      <Icon
        w={{ base: '70px', lg: '100px' }}
        h={{ base: '70px', lg: '100px' }}
        as={image}
        transition="all 0.3s"
        _groupHover={{
          color: isOdd ? 'green.500' : 'black',
          transform: 'rotate(90deg)',
        }}
      />
      <Link href={link} isExternal onClick={() => gaButtonHandler(name)}>
        <Text
          textTransform="uppercase"
          fontWeight="700"
          py="20px"
          _hover={{ color: isOdd ? 'green.500' : 'black' }}
          fontSize={{ base: '22px', lg: '28px' }}
        >
          {isEn ? content : contentZh}
        </Text>
      </Link>
      <Flex flex="1" align="flex-end">
        <Link href={link} isExternal onClick={() => gaButtonHandler(name)}>
          <Flex flex="1" align="flex-end">
            <Text
              _hover={{ color: isOdd ? 'green.500' : 'black' }}
              transition=".5s"
              _groupHover={{ transform: 'translateX(140px)' }}
            >
              READ MORE →
            </Text>
          </Flex>
        </Link>
      </Flex>
    </Flex>
  );
};

export default CourseCalendarCard;
