import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from '@emotion/styled';

// import SlickArrow from './SlickArrow';

const StyledSlider = styled(Slider)`
  .slick-track {
    display: flex;
  }
  .slick-list {
    position: relative;
  }
  .slick-slide {
    opacity: 0.5;
    padding: 30px 0;
    color: 'black';
  }

  .slick-slide p {
    color: black;
  }

  .slick-center p {
    color: #00ffb0;
  }

  .slick-center {
    opacity: 1;
    transform: scale(1.08);
  }
  /* .slick-dots {
    background: white;
  } */
  .slick-dots li button:before {
    color: #00ffb0 !important;
  }
  .slick-dots li.slick-active button:before {
    color: #00ffb0 !important;
  }
  /* .slick-slide {
    height: inherit;
    > div {
      height: 100%;
    }
  } */
`;
// nextArrow: <SlickArrow isLeft={false} />,
// prevArrow: <SlickArrow isLeft={true} />,

const settings = {
  dots: false,
  infinite: true,
  speed: 550,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
  ],
};

const SlickCenterMode = props => {
  const { slickSetting, ...reset } = props;
  return (
    <StyledSlider {...slickSetting} {...reset}>
      {props.children}
    </StyledSlider>
  );
};

export default SlickCenterMode;
