import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, chakra, Box, VStack, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import visionMG from '../../assets/images/vision/visionMG.mov';

const MotionBox = motion(Box);

const Vision = () => {
  const videoRef = useRef();
  const { t } = useTranslation();

  const handleVideoPlay = () => {
    videoRef.current?.play();
  };

  const variants = {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'tween',
        duration: 0.5,
      },
    },
    hidden: {
      opacity: 0,
    },
  };

  return (
    <Flex
      id="vision"
      w="100%"
      py={{ base: '25px', lg: '55px' }}
      align="center"
      justify="center"
      direction="column"
      borderTop="1px"
      borderColor="green.500"
      px="18px"
    >
      <MotionBox
        display={{ base: 'none', lg: 'block' }}
        w="90%"
        ref={videoRef}
        initial="visible"
        whileInView="visible"
        viewport={{ once: false, amount: 0.8 }}
        variants={variants}
        onViewportEnter={() => {
          handleVideoPlay();
        }}
        maxW="550px"
        h="360px"
        as="video"
        src={visionMG}
        playsInline
        muted
        preload="auto"
        type="video"
        objectFit="contain"
      ></MotionBox>
      <VStack
        display={{ base: 'flex', lg: 'none' }}
        fontSize="75px"
        spacing="15px"
        lineHeight="75px"
      >
        <Text color="green.500">MISSION</Text>
        <Text color="green.500">AND</Text>
        <Text color="green.500">VISION</Text>
      </VStack>
      <Box maxW="500px" py="20px" px="15px">
        <chakra.span color="green.500" pr="8px">{`{`}</chakra.span>
        <chakra.span>{t('vision')}</chakra.span>
        <chakra.span color="green.500" pl="8px">{`}`}</chakra.span>
      </Box>
    </Flex>
  );
};

export default Vision;
