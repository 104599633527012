export const translationsZh = {
  about: '關於',
  about_content:
    '區塊鏈線上學院旨在成為 Web3 教育學院，為區塊鏈領域培養優質的應用與開發人才。培訓課程包括客製化的專業訓練、線上預錄基礎課程與線上直播互動進階課程，希望能讓社會大眾對於區塊鏈領域有更加深入的認識。The Z Institute 同時也提供區塊鏈顧問服務、企業區塊鏈內訓、專案開發與資安審計，解決區塊鏈最前線的需求。',
  about_LearnToEarn_title: 'Learn to Earn',
  about_LearnToEarn:
    '所有學員均會依照課堂表現與課程總成績，空投對應數量的 ZI Token，讓學員能夠在學習的同時也能獲得收益，實現『Learn to Earn』，ZI Token 可用於任何區塊鏈相關學習活動費用折抵。',
  about_DefiResearchTeam_title: 'Research Team',
  about_DefiResearchTeam:
    '由我們的優秀畢業校友組成的自發性讀書會，每週以讀書會形式一同研究最新的區塊鏈議題與趨勢，研究領域包含 DeFi、NFT、Solidity 等主題。',
  about_TopTierLecturers_title: '區塊鏈產業一線講師群',
  about_TopTierLecturers:
    '學院邀請的師資均是經過審核的區塊鏈產業一線人才，為學員提供優質的區塊鏈第一手資訊。',
  about_NFT_Certificate_title: 'NFT 畢業證書',
  about_NFT_Certificate:
    '每位畢業學員皆有專屬的 NFT 畢業證書，發行於 Solana 區塊鏈上，依據學員的課程表現可獲得不同形式的畢業證書。',
  about_Metaverse_Classroom_title: '元宇宙教室',
  about_Metaverse_Classroom:
    '使用互動式線上社交應用 Gather Town 進行教學，模擬線下教學場景，讓學員可在教室內自由活動進行切磋交流、進行小組討論，也可以與講師深入互動。',
  vision:
    'The Z Institute 致力於為區塊鏈產業培養一流的應用開發者，我們的目標是成為國際上頂尖的區塊鏈教育機構，專門提供一流的培訓課程以及區塊鏈顧問服務。',
  //student feedback
  student_feedback_01_name: '林O宏',
  student_feedback_01_content:
    '課程規劃非常完整，課程有挑戰性，若能完整吸收將會成為 DeFi 大神!',
  student_feedback_02_name: '張O銘',
  student_feedback_02_content:
    '謝謝你們邀請這些優秀的講師，在早期經營這項目非常不容易，希望能繼續辦下去，越做越好!',
  student_feedback_03_name: '劉O孟',
  student_feedback_03_content:
    '可以感受出課程內容的用心，邀請到的講師也都很不錯，未來改善串流的部分及 discord 內資訊統整會更好，感謝 The Z Institute 的每位夥伴及講師們！',
  student_feedback_04_name: '廖O宜',
  student_feedback_04_content:
    '非常感謝 Z Institute，助教提供課後資訊，整理共筆及回覆問題時很有效率，覺得很用心！',
  student_feedback_05_name: 'Anson Luk',
  student_feedback_05_content:
    '能透過課程找尋相同理念也對 NFT 有興趣的人，組建一個真正活躍的社群比真實上課內容更有價值，希望學院可以繼續努力，一同進步！',
  student_feedback_06_name: '江O宏',
  student_feedback_06_content:
    '感謝你們出來培養台灣區塊鏈人才、凝聚這麼多這麼優秀的人，幸好我們這組生存率頗高，跟同儕間也學習到非常多！',
  student_feedback_07_name: '楊Ｏ凱',
  student_feedback_07_content:
    '我學到整個 solidity 大致上的開發流程會是怎樣而且更了解業界的生態，線下活動也能真的接觸到在區塊鏈產業工作的人，更確定我未來是真的想成為區塊鏈工程師！',

  // service
  service_01: '線上互動式課程',
  service_01_content:
    '由業界一流講師組成的講師團，在線上教室 Gather Town 進行實時授課，爲各為學員帶來最新的第一手專業資訊。線上直播課程提供專屬講師 Office Hour 與助教實線上專業解答，提供學員最好的互動學習環境！',
  service_02: '區塊鏈顧問服務',
  service_02_content:
    '提供中小企業區塊鏈領域轉型諮詢服務，內容包含應用設計、產業研究、底層架構等。',
  service_03: '資安審計',
  service_03_content: '提供最安全與有效的資安審計服務',
  service_04: '國際職缺媒合',
  service_04_content:
    '提供國際一流的區塊鏈企業職缺媒合，曾舉辦 Solidity 就業博覽會，參加企業包含 PureStake、Impossible Finance 等知名區塊鏈公司。',
  service_05: '客製化智能合約與串接服務 ',
  service_05_content: '能提供更多的客制化的智能合約服務。',

  //course
  course_action: '查看全部課程',

  //achieved
  achieved_title: '學院大事記',
  achieved_01: 'The Z Institute 建立 ',
  achieved_02: '第一屆 DeFi 實戰人才加速器開班',
  achieved_03: 'Solidity 智能合約開發班開課',
  achieved_03_02: 'Security Audit - Stader Labs ',
  achieved_04_01: '第二屆 DeFi 實戰人才加速器開課',
  achieved_04_02: 'Development - Opyn Polygon Integration',
  achieved_05_01: 'NFT & GameFi 實戰人才加速器開課',
  achieved_05_02: 'Security Audit - Edge Protocol	',

  //roadmap
  roadmap_2021_Q4_1: 'Bootcamp 課程開始',
  roadmap_2022_Q1_1: '線上課程籌劃',
  roadmap_2022_Q1_2: '建立Office hour 制度',
  roadmap_2022_Q2_1: '線上預錄課程主題與課綱釋出',
  roadmap_2022_Q2_2: '新 Bootcamp 課程報名資訊釋出：',
  roadmap_2022_Q2_2_1:
    'Security Bootcamp, Technical Writer, DeFi, NFT & GameFi, Solidity, Solana',
  roadmap_2022_Q3_1: 'Bootcamp 課程開始',
  roadmap_2022_Q3_2: '線上課程 Beta 版本釋出，學院內部進行優化',
  roadmap_2022_Q4_1: '教學文章定期釋出',
  roadmap_2022_Q4_2: 'Youtube 影片定期釋出',
  roadmap_2022_Q4_3: '線上課程全數上線',
  roadmap_2022_Q4_4: '視區塊鏈環境與技術趨勢，不定期開設相關主題 Bootcamp 課程',

  //instructor
  first: '第一屆',
  second: '第二屆',
  instructors_01: 'DeFi 實戰人才加速器',
  instructors_01_teacher_01_name: '小葉',
  instructors_01_teacher_01_title: 'DeFi 實戰人才加速器第一屆優秀校友',
  instructors_01_teacher_02_name: '瞿孝洋',
  instructors_01_teacher_02_title:
    'Founder of deFintek & Taiwan DeFi Study Group',
  instructors_01_teacher_03_name: 'Tom',
  instructors_01_teacher_03_title: 'Economist of LikeCoin',
  instructors_01_teacher_04_name: 'Zoni',
  instructors_01_teacher_04_title: 'Researcher at Footprint',
  instructors_01_teacher_05_name: 'Tina',
  instructors_01_teacher_05_title:
    'Founder of The Z Institute, blockchain researcher and security auditor since 2017',
  instructors_01_teacher_06_name: 'Anderson Chen',
  instructors_01_teacher_06_title:
    'Co-Founder & CTO at Coinomo, Founder & CEO at Dapp Pocket',
  instructors_02: 'NFT & GameFi 實戰人才加速器',
  instructors_02_teacher_01_name: '史旺基',
  instructors_02_teacher_01_title:
    '區塊鏈新創國際信任機器顧問、東吳大學兼任助理教授',
  instructors_02_teacher_02_name: '寶博士',
  instructors_02_teacher_02_title:
    'Assistant Professor @ NTUT、Founder of Memora & Luna',
  instructors_02_teacher_03_name: '胡新致',
  instructors_02_teacher_03_title:
    'Marketing Director of RE:DREAMER, Evangelist of Fansi',
  instructors_02_teacher_04_name: '吳哲宇',
  instructors_02_teacher_04_title: '新媒體生成式藝術家，墨雨設計創辦人',
  instructors_02_teacher_05_name: '李婷婷',
  instructors_02_teacher_05_title:
    'Founder of The Z Institute, blockchain researcher and security auditor since 2017',
  instructors_02_teacher_06_name: '阿亂',
  instructors_02_teacher_06_title:
    '台灣藝術創作者，第一位登上生成式藝術殿堂 ArtBlocks 的台灣人',
  instructors_02_teacher_07_name: '陳慕天',
  instructors_02_teacher_07_title:
    'Rug Pull Frens 計畫發起人，美感教科書計畫創辦人, 文化銀行創辦人',
  instructors_02_teacher_08_name: '水丰刀',
  instructors_02_teacher_08_title: '閱部客公司創辦人，YouTuber, 閱學院創辦',
  instructors_02_teacher_09_name: '瑞奇隊長',
  instructors_02_teacher_09_title: 'TomoTouch 創辦人',
  instructors_02_teacher_10_name: '周永祐',
  instructors_02_teacher_10_title: 'NFT 藝術家',
  instructors_03: '智能合約開發班',
  instructors_03_teacher_02_name: 'Justa',
  instructors_03_teacher_02_title: 'Developer of MetaJungle ',
  instructors_03_teacher_03_name: 'Jim',
  instructors_03_teacher_03_title: 'Tech Lead of Dappio',
  instructors_03_teacher_04_name: 'Wei',
  instructors_03_teacher_04_title: 'Developer of Dappio',
  instructors_03_teacher_05_name: 'Tim',
  instructors_03_teacher_05_title: 'Developer of Dappio',
  instructors_03_teacher_06_name: 'Jeffrey',
  instructors_03_teacher_06_title: 'Founder of Rebirth-NFT',
  instructors_03_teacher_07_name: 'Justin Starry',
  instructors_03_teacher_07_title: 'Solana Core Engineer',
  instructors_03_teacher_08_name: 'CopyCat',
  instructors_03_teacher_08_title: 'Solana 中文社群管理員',
  instructors_03_teacher_09_name: 'Kazami',
  instructors_03_teacher_09_title: 'Blockchain evangelist / Data scientist',
  instructors_03_teacher_01_name: 'Tina',
  instructors_03_teacher_01_title:
    'Founder of the Z Institute, blockchain researcher and security auditor since 2017',

  //partner
  partner_subtitle:
    '與我們學院長期配合智能合約服務、資安審計、企業內訓以及人才媒合的合作夥伴。',
  //QA
  q1: '上課形式是全部線上嗎?是否會有線下課程? ',
  a1: '目前課程皆是線上課程，未來有機會將會推出線下課程，敬請期待!',
  q2: '課程是否有錄影? ',
  a2: '每堂課皆會提供學員課堂錄影，建議學員親自至線上上課，方能直接與講師互動。',
  q3: '課程結束後是否能夠訪問課堂素材? ',
  a3: '可以。學員在課程結束後同樣能複習課程素材。',
  q4: '該如何繳費?',
  a4: '學員報名時可以使用台幣轉帳或是穩定幣轉帳形式付費，學院將會在收到款項後發放確認信。',

  //news
  news_01_title: 'ZNFT − Web 3 元宇宙大學的通行證',
  news_01_content:
    '自從去年因緣際會創辦了 The Z Institute，我們就一直在思考，在 Web 3.0 的教育機構應該是什麼樣子？',
  news_02_title: '全台灣首場「智能合約工程師媒合活動」',
  news_02_content:
    'Solidity 智能合約工程師開發班於去年十二月首度開辦，Z 學院希望能夠透過為期三個月的密集訓練，幫助學員們搭上',
  news_03_title:
    'ERC721R 合約實測！防 Rugpull 的合約竟然可以被用來 Rugpull！？',
  news_03_content:
    '點燃推特輿論，號稱能夠在有效期限內退回 NFT ，並回收鑄造費用的 ERC 721R 協議背後的邏輯為何？如何實際應用在智能合約撰寫上？鑄造之後真的能夠無限制退款嗎？',

  //contact
  contact_intro:
    'The Z Institute 提供專業的區塊鏈顧問與客製化開發服務，於以太坊、幣安智能鏈等 EVM 兼容區塊鏈、EOS、與 Solana 皆有開發經驗。專案類型曾開發過 NFT、博弈、遊戲、代幣合約等。可接受全程匿名開發與 100% 虛擬貨幣支付，亦有配合律師可協助虛擬貨幣法務。',
  contact_name: '姓名',
  contact_email: '電子郵件',
  contact_title: '任職公司/職稱',
  contact_web: '任職公司官網',
  contact_interest: '欲諮詢的事項',
  contact_smartContract: '智能合約開發',
  contact_dapp: 'Dapp 區塊鏈應用開發',
  contact_consulting_business: '企業區塊鏈解決方案諮詢',
  contact_training: '企業區塊鏈員工內訓',
  contact_school: '校園區塊鏈課程',
  contact_project: '區塊鏈項目推廣合作',
  contact_others: '其他',
  contact_detail: '諮詢細節',
  contact_detail_placeholder:
    '需求請填寫具體與詳細，幫助我們更快了解並解決您的問題！若為課程需求，請提供欲學習的主題名稱、範圍、學生背景與人數、線上或線下等。',

  //footer
  footer_about_01: '關於我們',
  footer_about_02: '加入我們',
  footer_about_03: '消費者保護條款',
  footer_about_04: '著作權政策',
  footer_about_05: '隱私權政策',
  footer_help_01: '企業學習方案',
  footer_help_02: '異界合作洽談',
};
