import ReactGA from 'react-ga4';
import { Flex, Heading, Text, Avatar, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Slick from '../Slick';
import ClassInstructors from 'components/ClassInstructors';
import useSpace from '../../hooks/useSpace';

import defi01 from '../../assets/images/instructors/defi_01.jpg';
import defi02 from '../../assets/images/instructors/defi_02.jpg';
import defi03 from '../../assets/images/instructors/defi_03.jpg';
import defi04 from '../../assets/images/instructors/defi_04.jpg';
import defi05 from '../../assets/images/instructors/defi_05.jpg';
import defi06 from '../../assets/images/instructors/defi_06.jpg';
import defi07 from '../../assets/images/instructors/defi_07.jpg';

import nft01 from '../../assets/images/instructors/nft_01.jpg';
import nft02 from '../../assets/images/instructors/nft_02.jpg';
import nft03 from '../../assets/images/instructors/nft_03.jpg';
import nft04 from '../../assets/images/instructors/nft_04.jpg';
import nft05 from '../../assets/images/instructors/nft_05.jpg';
import nft06 from '../../assets/images/instructors/nft_06.jpg';
import nft07 from '../../assets/images/instructors/nft_07.jpg';
import nft08 from '../../assets/images/instructors/nft_08.jpg';
import nft09 from '../../assets/images/instructors/nft_09.jpg';
import nft10 from '../../assets/images/instructors/nft_10.jpg';

import develop01 from '../../assets/images/instructors/develop_01.jpg';
import develop02 from '../../assets/images/instructors/develop_02.jpg';
import develop03 from '../../assets/images/instructors/develop_03.jpg';
import develop04 from '../../assets/images/instructors/develop_04.jpg';
import develop05 from '../../assets/images/instructors/develop_05.jpg';
import develop06 from '../../assets/images/instructors/develop_06.jpg';
import develop07 from '../../assets/images/instructors/develop_07.jpg';
import develop08 from '../../assets/images/instructors/develop_08.jpg';
import develop09 from '../../assets/images/instructors/develop_09.jpg';

import defi3_instructor from 'assets/csv/defi3_instructor.csv';
// import nft_instructor from 'assets/csv/nft_instructor.csv';

const slickSettings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 800,
  autoplay: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  onSwipe: () => {
    ReactGA.event({
      category: 'Instructor',
      action: 'InstructorSwipe',
    });
  },
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
      },
    },
  ],
};

const instructorsList = [
  {
    session: 'second',
    class: 'instructors_01',
    members: [
      {
        name: 'instructors_01_teacher_01_name',
        title: 'instructors_01_teacher_01_title',
        avatar: defi01,
      },
      {
        name: 'instructors_01_teacher_02_name',
        title: 'instructors_01_teacher_02_title',
        link: 'https://www.facebook.com/reyer.chu',
        avatar: defi02,
      },
      {
        name: 'instructors_01_teacher_03_name',
        title: 'instructors_01_teacher_03_title',
        avatar: defi03,
      },
      {
        name: 'instructors_01_teacher_04_name',
        title: 'instructors_01_teacher_04_title',
        avatar: defi04,
      },
      {
        name: 'instructors_01_teacher_05_name',
        title: 'instructors_01_teacher_05_title',
        link: 'https://www.facebook.com/lee.ting.ting.tina',
        avatar: defi05,
      },
      // {
      //   name: 'Jerry Ho',
      //   title: 'Researcher at SCRF (Smart Contract Research Forum)',
      //   avatar: defi06,
      // },
      {
        name: 'instructors_01_teacher_06_name',
        title: 'instructors_01_teacher_06_title',
        avatar: defi07,
        link: 'https://www.facebook.com/anderson.numberone',
      },
    ],
  },
  {
    session: 'first',
    class: 'instructors_02',
    members: [
      {
        name: 'instructors_02_teacher_01_name',
        title: 'instructors_02_teacher_01_title',
        avatar: nft01,
        link: 'https://www.facebook.com/swanky',
      },
      {
        name: 'instructors_02_teacher_02_name',
        title: 'instructors_02_teacher_02_title',
        avatar: nft02,
        link: 'https://www.facebook.com/dAAAb',
      },
      {
        name: 'instructors_02_teacher_03_name',
        title: 'instructors_02_teacher_03_title',
        avatar: nft03,
        link: 'https://www.facebook.com/eason.hu2',
      },
      {
        name: 'instructors_02_teacher_04_name',
        title: 'instructors_02_teacher_04_title',
        avatar: nft04,
        link: 'https://www.facebook.com/cheyuwu345',
      },
      {
        name: 'instructors_02_teacher_05_name',
        title: 'instructors_02_teacher_05_title',
        avatar: nft05,
        link: 'https://www.facebook.com/lee.ting.ting.tina',
      },
      {
        name: 'instructors_02_teacher_06_name',
        title: 'instructors_02_teacher_06_title',
        avatar: nft06,
        link: 'https://www.facebook.com/Aluan.wang',
      },
      {
        name: 'instructors_02_teacher_07_name',
        title: 'instructors_02_teacher_07_title',
        avatar: nft07,
        link: 'https://www.facebook.com/RobotAI521',
      },
      {
        name: 'instructors_02_teacher_08_name',
        title: 'instructors_02_teacher_08_title',
        avatar: nft08,
        link: 'https://www.facebook.com/yuubuke/',
      },
      {
        name: 'instructors_02_teacher_09_name',
        title: 'instructors_02_teacher_09_title',
        avatar: nft09,
        link: 'https://www.linkedin.com/in/chenricky/',
      },
      {
        name: 'instructors_02_teacher_10_name',
        title: 'instructors_02_teacher_10_title',
        avatar: nft10,
        link: 'https://twitter.com/zhouyongyou',
      },
    ],
  },
  {
    session: 'first',
    class: 'instructors_03',
    members: [
      {
        name: 'instructors_03_teacher_02_name',
        title: 'instructors_03_teacher_02_title',
        avatar: develop02,
        link: 'https://twitter.com/metajunglenft?lang=zh-Hant',
      },
      {
        name: 'instructors_03_teacher_03_name',
        title: 'instructors_03_teacher_03_title',
        avatar: develop03,
        link: 'https://dappio.xyz/',
      },
      {
        name: 'instructors_03_teacher_04_name',
        title: 'instructors_03_teacher_04_title',
        avatar: develop04,
        link: 'https://dappio.xyz/',
      },
      {
        name: 'instructors_03_teacher_05_name',
        title: 'instructors_03_teacher_05_title',
        avatar: develop05,
        link: 'https://dappio.xyz/',
      },
      {
        name: 'instructors_03_teacher_06_name',
        title: 'instructors_03_teacher_06_title',
        avatar: develop06,
      },
      {
        name: 'instructors_03_teacher_07_name',
        title: 'instructors_03_teacher_07_title',
        avatar: develop07,
      },
      {
        name: 'instructors_03_teacher_08_name',
        title: 'instructors_03_teacher_08_title',
        avatar: develop08,
        link: 'https://mobile.twitter.com/copycat_sol',
      },
      {
        name: 'instructors_03_teacher_09_name',
        title: 'instructors_03_teacher_09_title',
        avatar: develop09,
      },
      {
        name: 'instructors_03_teacher_01_name',
        title: 'instructors_03_teacher_01_title',
        avatar: develop01,
        link: 'https://www.facebook.com/lee.ting.ting.tina',
      },
    ],
  },
];

const Instructors = () => {
  const { t } = useTranslation();
  const { space } = useSpace();

  return (
    <Flex w="100%" justify="center" py="50px" px="18px">
      <Flex w={space} direction="column" align="center">
        <Heading
          textTransform="uppercase"
          pb="80px"
          fontSize={{ base: '60px', lg: '90px' }}
          display={{ base: 'none', lg: 'block' }}
        >
          Instructors
        </Heading>
        <Heading
          textTransform="uppercase"
          pb="60px"
          fontSize={{ base: '60px', lg: '90px' }}
          display={{ base: 'block', lg: 'none' }}
        >
          Ins <br />
          tructors
        </Heading>
        <ClassInstructors csv={defi3_instructor} />
        {instructorsList.map(instructor => (
          <Flex
            w={{ base: '90%', lg: '100%' }}
            direction="column"
            py={{ base: '10px', lg: '60px' }}
            key={instructor.class}
          >
            <Flex
              direction="column"
              borderLeft="1px"
              borderColor="green.500"
              pl="32px"
              pt="0px"
              mb="30px"
              justify="flex-end"
              align="flex-start"
            >
              <Text fontSize={{ base: '14px', lg: '20px' }}>
                {t(instructor.session)}
              </Text>
              <Heading
                color="green.500"
                fontSize={{ base: '20px', lg: '30px' }}
                pt={{ base: '2px', lg: '12px' }}
              >
                {t(instructor.class)}
              </Heading>
            </Flex>
            <Slick
              slickSetting={slickSettings}
              style={{
                display: 'flex',
                justify: 'center',
                alignItems: 'stretch',
                position: 'relative',
                width: '100%',
                borderRadius: '5px',
              }}
            >
              {instructor.members.map(member => (
                <Flex
                  h="100%"
                  w="100px"
                  justify="center"
                  px="20px"
                  key={member.name + instructor.class}
                >
                  <Flex
                    w="100%"
                    align="center"
                    justify="center"
                    direction="column"
                  >
                    <Link href={member.link} isExternal>
                      <Avatar
                        w="100px"
                        h="100px"
                        name={t(member.name)}
                        src={member.avatar}
                      />
                    </Link>
                    <Text
                      fontSize="27px"
                      pt="20px"
                      pb="5px"
                      textAlign="center"
                      w="200px"
                    >
                      {t(member.name)}
                    </Text>
                    <Text
                      fontSize={{ base: '14px', lg: '16px' }}
                      lineHeight={{ base: '20px', lg: '28px' }}
                      fontWeight="500"
                      textAlign="center"
                    >
                      {t(member.title)}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Slick>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default Instructors;
