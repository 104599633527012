import React, { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import Home from './pages/Home';

function App() {
  useEffect(() => {
    hotjar.initialize('2919570', 6);
  }, []);
  return (
    <>
      <Home />
    </>
  );
}

export default App;
