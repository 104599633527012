const useSpace = () => {
  const space = {
    base: '100%',
    md: '90%',
    lg: '80%',
    xl: '960px',
    '2xl': '1280px',
  };

  const spaceY = {
    base: '50px',
    lg: '80px',
  };

  const gutter = '18px';

  return {
    space,
    spaceY,
    gutter,
  };
};

export default useSpace;
