import { HStack, Text, Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Counter from './Counter';

const Divider = () => {
  return <Box borderRight="1px solid black" h="auto"></Box>;
};

const AboutStatusBar = () => {
  const { i18n } = useTranslation();
  const isEn = i18n.language === 'en';
  return (
    <HStack
      display={{ base: 'none', lg: 'flex' }}
      bg="green.500"
      w="100%"
      h="120px"
      color="black"
      px="60px"
      align="stretch"
      py="20px"
      textTransform="uppercase"
      justify="space-around"
    >
      <HStack>
        <Text fontSize="30px" bgImage="20px" lineHeight="30px" fontWeight="500">
          {isEn ? 'Student' : '學生'}
          <br />
          {isEn ? 'member' : '數量'}
        </Text>
        <Counter from={0} to={2077} />
        <Text alignSelf="flex-end" fontSize="30px">
          ↑
        </Text>
      </HStack>
      <Divider />
      <HStack>
        <Text fontSize="30px" lineHeight="30px" fontWeight="500">
          {isEn ? 'classes' : '開設'}
          <br />
          {isEn ? 'member' : '班級數'}
        </Text>
        <Counter from={0} to={4} time={0.5} />
        <Text alignSelf="flex-end" fontSize="30px">
          ↑
        </Text>
      </HStack>
      <Divider />
      <HStack>
        <Text fontSize="30px" lineHeight="30px" fontWeight="500">
          NFT
          <br />
          Holder
        </Text>
        <Text fontSize="70px" fontWeight="700">
          ???
        </Text>
        {/* <Text alignSelf="flex-end" fontSize="30px">
          ↑
        </Text> */}
      </HStack>
      <Divider />
      <HStack>
        <Text fontSize="30px" lineHeight="30px" fontWeight="500">
          {isEn ? 'partner' : '配合的'}
          <br />
          {isEn ? 'company' : '區塊鏈公司'}
        </Text>
        <Counter from={0} to={12} time={1} />
        <Text alignSelf="flex-end" fontSize="30px">
          ↑
        </Text>
      </HStack>
    </HStack>
  );
};

export default AboutStatusBar;
