import { extendTheme } from '@chakra-ui/react';
import colors from './colors';
// import Button from './components/button';

const customTheme = {
  // initialColorMode: 'light',
  // useSystemColorMode: false,
  fonts: {
    heading: "'IBM Plex Mono', Sans-serif",
    body: "'IBM Plex Mono', Sans-serif",
  },
  styles: {
    global: {
      background: 'black',
      color: 'white',
      // styles for the `body`
      body: {
        bg: 'black',
        color: 'white',
      },
      '::selection': {
        color: 'black',
        backgroundColor: 'green.500',
      },
    },
  },
  colors,
  components: {
    //setting components theme
    // Button,
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: 'none',
          color: 'green.500',
        },
        _focus: {
          outline: '0px',
          boxShadow: 'unset',
        },
      },
    },
  },
};

const theme = extendTheme(customTheme);

export default theme;
