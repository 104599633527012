import ReactGA from 'react-ga4';
import { Flex, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Slick from './Slick';

const slickSetting = {
  dots: true,
  infinite: true,
  speed: 550,
  autoplay: false,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  onSwipe: () => {
    ReactGA.event({
      category: 'StudentFeedback',
      action: 'StudentFeedbackSwipe',
    });
  },
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
  ],
};

const feedbacksGroup = [
  [
    {
      name: 'student_feedback_01_name',
      time: 'Z22012007',
      content: 'student_feedback_01_content',
    },
    {
      name: 'student_feedback_02_name',
      time: 'Z22013019',
      content: 'student_feedback_02_content',
    },
    {
      name: 'student_feedback_03_name',
      time: 'Z22013001',
      content: 'student_feedback_03_content',
    },
  ],
  [
    {
      name: 'student_feedback_04_name',
      time: 'Z22013010',
      content: 'student_feedback_04_content',
    },
    {
      name: 'student_feedback_05_name',
      time: 'Z22013067',
      content: 'student_feedback_05_content',
    },
    {
      name: 'student_feedback_06_name',
      time: '110AB8044',
      content: 'student_feedback_06_content',
    },
  ],
];

const feedbacks = [
  {
    name: 'student_feedback_01_name',
    time: 'Z22012007',
    content: 'student_feedback_01_content',
  },
  {
    name: 'student_feedback_02_name',
    time: 'Z22013019',
    content: 'student_feedback_02_content',
  },
  {
    name: 'student_feedback_03_name',
    time: 'Z22013001',
    content: 'student_feedback_03_content',
  },

  {
    name: 'student_feedback_04_name',
    time: 'Z22013010',
    content: 'student_feedback_04_content',
  },
  {
    name: 'student_feedback_05_name',
    time: 'Z22013067',
    content: 'student_feedback_05_content',
  },
  {
    name: 'student_feedback_06_name',
    time: 'Z110AB8044',
    content: 'student_feedback_06_content',
  },
];

const soleFeedback = {
  name: 'student_feedback_07_name',
  time: 'Z22013067',
  content: 'student_feedback_07_content',
};

const CardFeedback = props => {
  const { t } = useTranslation();
  const { name, time, content } = props;
  return (
    <Flex
      w="100%"
      bg="black"
      align="center"
      direction={{ base: 'column', lg: 'row' }}
      justify="flex-start"
    >
      <Flex w={{ base: '100%', lg: '30%' }} direction="column">
        <Text textTransform="uppercase" fontSize="30px" color="white">
          {t(name)}
        </Text>
        <Text textTransform="uppercase" fontSize="15px" color="green.500">
          {time}
        </Text>
      </Flex>
      <Flex w={{ base: '100%', lg: '60%' }}>
        <Text>{t(content)}</Text>
      </Flex>
    </Flex>
  );
};

const CardFeedbacks = props => {
  return (
    <Flex
      h={{ base: 'unset', lg: '400px' }}
      direction="column"
      justify="space-between"
      px="40px"
    >
      {props.feedbacks.map(feedback => (
        <CardFeedback key={feedback.name} {...feedback} />
      ))}
      {/* <CardFeedback /> */}
    </Flex>
  );
};

const AboutStudentsFeedback = () => {
  return (
    <Flex
      w="100%"
      justify="center"
      align="center"
      py={{ base: '80px', lg: '180px' }}
      direction="column"
    >
      <Heading textTransform="uppercase" textAlign="center" py="20px">
        Student feedback
      </Heading>
      <Flex
        w="100%"
        maxW="1080px"
        justify="center"
        display={{ base: 'none', lg: 'flex' }}
      >
        <Slick
          style={{
            width: '100%',
            padding: '30px 0',
          }}
          slickSetting={slickSetting}
        >
          {feedbacksGroup.map((feedbacks, index) => (
            <CardFeedbacks key={index + 'feedback'} feedbacks={feedbacks} />
          ))}
          <CardFeedback {...soleFeedback} />
        </Slick>
      </Flex>
      <Flex
        w="100%"
        px="20px"
        maxW="1080px"
        justify="center"
        display={{ base: 'flex', lg: 'none' }}
      >
        <Slick
          style={{
            width: '100%',
            padding: '10px 0',
          }}
          slickSetting={slickSetting}
        >
          {feedbacks.map((feedback, index) => (
            <CardFeedback key={feedback.name} {...feedback} />
          ))}
          <CardFeedback {...soleFeedback} />
        </Slick>
      </Flex>
    </Flex>
  );
};

export default AboutStudentsFeedback;
