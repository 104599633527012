import ReactGA from 'react-ga4';
import { Link as ReachLink } from 'react-scroll';
import { Flex, Text, chakra, Link, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import SocialLink from '../SocialLink';
import useSpace from '../../hooks/useSpace';

const aboutList = [
  {
    title: 'footer_about_01',
    to: 'about',
  },
  {
    title: 'footer_about_02',
    link: 'https://www.cakeresume.com/companies/the-z-institute/jobs',
  },
  {
    title: 'footer_about_03',
    link: 'https://www.zinstitute.online/%E6%B6%88%E8%B2%BB%E8%80%85%E4%BF%9D%E8%AD%B7%E6%A2%9D%E6%AC%BE/',
  },
  // {
  //   title: 'footer_about_04',
  //   link: 'https://www.zinstitute.online/%E8%91%97%E4%BD%9C%E6%AC%8A%E6%94%BF%E7%AD%96/',
  // },
  {
    title: 'footer_about_05',
    link: 'https://zinstitute.kaik.io/terms',
  },
];

const helpList = [
  {
    title: 'footer_help_01',
    to: 'Contact',
  },
  {
    title: 'footer_help_02',
    to: 'Contact',
  },
];

const Footer = () => {
  const { t } = useTranslation();
  const { space } = useSpace();

  const gaButtonHandler = event => {
    ReactGA.event({
      category: 'footer',
      action: `footerClick_${event}`,
    });
  };
  return (
    <Flex
      w="100%"
      px="18px"
      py={{ base: '90px', lg: '120px' }}
      bgImage="linear-gradient(rgba(0,255,176,0.1) 1px, transparent 1px), linear-gradient(to right, rgba(0,255,176,0.1) 1px, black 1px)"
      bgSize="20px 20px"
      borderTop="1px"
      borderColor="green.500"
      justify="center"
    >
      <Flex w={space} direction={{ base: 'column', lg: 'row' }}>
        <Flex w={{ base: '100%', lg: '35%' }} justify="space-between">
          <Flex direction="column" align="flex-start" pr="25px">
            <Text fontSize="30px" color="green.500">
              ABOUT →
            </Text>
            <VStack spacing="10px" align="flex-start" pt="20px">
              {aboutList.map(item => (
                <Flex key={item.title}>
                  {item.to && (
                    <Link
                      as={ReachLink}
                      smooth={true}
                      to={item.to}
                      offset={-50}
                      spy={true}
                      hashSpy={true}
                      onClick={() => gaButtonHandler(item.title)}
                    >
                      {t(item.title)}
                    </Link>
                  )}
                  {!item.to && (
                    <Link
                      href={item.link}
                      onClick={() => gaButtonHandler(item.title)}
                      isExternal
                    >
                      {t(item.title)}
                    </Link>
                  )}
                </Flex>
              ))}
            </VStack>
          </Flex>
          <Flex direction="column" align="flex-start">
            <Text fontSize="30px" color="green.500">
              HELP →
            </Text>
            <VStack spacing="10px" align="flex-start" pt="20px">
              {helpList.map(item => (
                <Flex key={item.title}>
                  {item.to && (
                    <Link
                      as={ReachLink}
                      smooth={true}
                      to={item.to}
                      offset={-50}
                      spy={true}
                      hashSpy={true}
                      onClick={() => gaButtonHandler(item.title)}
                    >
                      {t(item.title)}
                    </Link>
                  )}
                  {!item.to && (
                    <Link
                      href={item.link}
                      onClick={() => gaButtonHandler(item.title)}
                      isExternal
                    >
                      {t(item.title)}
                    </Link>
                  )}
                </Flex>
              ))}
            </VStack>
          </Flex>
        </Flex>
        <Flex
          w={{ base: '100%', lg: '65%' }}
          // align="flex-end"
          align={{ base: 'flex-start', lg: 'flex-end' }}
          direction="column"
          pt={{ base: '30px', lg: '0px' }}
        >
          <Text pb="15px" color="green.500">
            Z INSTITUTE © All rights reserved.
          </Text>
          <SocialLink />
          <Flex flex="1" align="flex-end">
            <VStack
              align={{ base: 'flex-start', lg: 'flex-end' }}
              pt={{ base: '20px', lg: '0' }}
            >
              <Text>
                <chakra.span>Email:</chakra.span>
                <Link ml="10px" href="mailto:marketing@zinstitute.net">
                  marketing@zinstitute.net
                </Link>
              </Text>
              <Text>
                <chakra.span>Tel:</chakra.span>
                <Link ml="10px" href="tel:+852-2388-8383">
                  0989955446
                </Link>
              </Text>
            </VStack>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
