import { Flex, Heading, Text, Image, Grid, GridItem } from '@chakra-ui/react';
import useSpace from '../../hooks/useSpace';
import { useTranslation } from 'react-i18next';

import partner from '../../assets/images/partner.gif';
import brand01 from '../../assets/images/brand/brand01.png';
import brand02 from '../../assets/images/brand/brand02.png';
import brand03 from '../../assets/images/brand/brand03.png';
import brand04 from '../../assets/images/brand/brand04.png';
import brand05 from '../../assets/images/brand/brand05.png';
import brave from '../../assets/images/brand/brave.png';
import lootex from '../../assets/images/brand/lootex.png';
import metaBoom from '../../assets/images/brand/metaBoom.png';
import momentX from '../../assets/images/brand/momentX.png';
import reDreamer from '../../assets/images/brand/reDreamer.png';
import solex from '../../assets/images/brand/solex.png';
import treasurelink from '../../assets/images/brand/treasurelink.png';

const partnerBrand = [
  {
    name: 'treasurelink',
    image: treasurelink,
  },
  {
    name: 'momentX',
    image: momentX,
  },
  {
    name: 'brand02',
    image: brand02,
  },
  {
    name: 'brand03',
    image: brand03,
  },
  {
    name: 'brand04',
    image: brand04,
  },
  {
    name: 'reDreamer',
    image: reDreamer,
  },

  {
    name: 'brave',
    image: brave,
  },
  {
    name: 'lootex',
    image: lootex,
  },
  {
    name: 'metaBoom',
    image: metaBoom,
  },
  {
    name: 'brand05',
    image: brand05,
  },

  {
    name: 'solex',
    image: solex,
  },

  {
    name: 'brand01',
    image: brand01,
  },
];

const Partner = () => {
  const { space } = useSpace();
  const { t } = useTranslation();
  return (
    <Flex
      w="100%"
      justify="center"
      align="center"
      my={{ base: '40px', lg: '80px' }}
    >
      <Flex w={space} direction="column" align="center">
        <Flex
          w="100%"
          px="18px"
          maxW="900px"
          pb={{ base: '30px', lg: '60px' }}
          direction={{ base: 'column', lg: 'row' }}
        >
          <Flex
            direction="column"
            mr={{ base: '0', lg: '120px' }}
            mb={{ base: '20px', lg: '0' }}
            justify="center"
          >
            <Heading
              textTransform="uppercase"
              fontSize={{ base: '56px', lg: '90px' }}
              fontWeight="400"
              color="green.500"
            >
              Partner
            </Heading>
            <Text>{t('partner_subtitle')}</Text>
          </Flex>
          <Image w="100%" maxW="500px" src={partner} rounded="md" />
        </Flex>
        <Grid
          w="80%"
          maxW="10800px"
          pt={{ base: '20px', lg: '50px' }}
          // templateColumns="repeat(5, 1fr)"
          templateColumns={{ base: 'repeat(3, 1fr)', lg: 'repeat(5, 1fr)' }}
          gap={10}
        >
          {partnerBrand.map(brand => (
            <GridItem
              key={brand.name}
              colSpan={1}
              display="flex"
              justifyContent="center"
            >
              <Image
                // filter="grayscale(100%)"
                w={{ base: '70px', lg: '140px' }}
                h={{ base: '50px', lg: '80px' }}
                objectFit="contain"
                opacity=".9"
                alt={brand.name}
                src={brand.image}
              />
            </GridItem>
          ))}
        </Grid>
      </Flex>
    </Flex>
  );
};

export default Partner;
